<template>
  <div class="video-iframe">
    <iframe :src="src" />
  </div>
</template>

<script>
import { currentUser } from "@/api/video.js";
export default {
  name: "videoIframe",
  data() {
    return {
      src: "",
    };
  },
  mounted() {
    this.getVideoSrc();
  },
  methods: {
    getVideoSrc() {
      currentUser().then((res) => {
        this.src = `https://www.ajedudahua.com:449/evo-web-video/index.html#/video?userName=${res.data.data.userAdmin}&token=${res.data.data.token}&orgCode=${res.data.data.orgCode}&originType=2`;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.video-iframe {
  background-color: #fff;
  padding: 15px;
  height: calc(100vh - 154px);
  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
