import { fetchEndpoint } from "@/server/network1";
import axios from "axios";

//获取学校三防信息
export const getThreeProofingsList = (data) =>
  fetchEndpoint(
    "/school/threeProofings/getThreeProofingsList",
    { ...data },
    false,
    "POST"
  );

//获取三防详情信息
export const getThreeProofingsById = (data) =>
  fetchEndpoint("/school/threeProofings/getThreeProofingsById", { ...data });

//删除信息
export const delThreeProofings = (data) =>
  fetchEndpoint("/school/threeProofings/delThreeProofings", { ...data });

//添加
export const addThreeProofing = (data) =>
  fetchEndpoint("/school/threeProofings/addThreeProofing", { ...data });

//修改
export const upaThreeProofings = (data) =>
  fetchEndpoint("/school/threeProofings/upaThreeProofings", { ...data });

//获取类目
export const getTypeList = (data) =>
  fetchEndpoint("/school/threeProofings/getTypeList", { ...data });

//查看学校基本信息
export const getSchoolDetail = () =>
  fetchEndpoint(
    "/school/BaseInfoSchoolDetail/getSchoolDetail",
    {},
    true,
    "get"
  );
