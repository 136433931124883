<template>
  <div class="elementPage">
    <a-button>测试按钮</a-button>
    <a-button type="primary">测试按钮</a-button>
    <a-button type="link">测试按钮</a-button>

    <a-radio-group name="radioGroup" :default-value="1">
      <a-radio :value="1">
        A
      </a-radio>
      <a-radio :value="2">
        B
      </a-radio>
      <a-radio :value="3">
        C
      </a-radio>
      <a-radio :value="4">
        D
      </a-radio>
    </a-radio-group>
    <a-radio-group default-value="a" button-style="solid">
      <a-radio-button value="a">
        Hangzhou
      </a-radio-button>
      <a-radio-button value="b">
        Shanghai
      </a-radio-button>
      <a-radio-button value="c">
        Beijing
      </a-radio-button>
      <a-radio-button value="d">
        Chengdu
      </a-radio-button>
    </a-radio-group>

    <i class="iconfont icon-shebei1"></i>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "elementPage",
  data() {
    return {
      imageUrl: [],
    };
  },
  components: {},
  created() {
    this.modifyPageT("编辑角色");
  },
  methods: {
    ...mapMutations(["modifyPageT"]),
  },
};
</script>

<style lang="less" scoped>
.icon_area {
  padding: 30px;
  background: #242836;
}
</style>
