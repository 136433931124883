<template>
  <div class="admin_page_title">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item v-for="(item, index) in pageTitle" :key="index">{{
        item
      }}</a-breadcrumb-item>
    </a-breadcrumb>
    <!-- <div class="page_title">
      {{ pageTitle }}
    </div> -->
    <!-- <a-button size="default" icon="left" @click="renturnPage">返回</a-button> -->
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "adminPageTitle",
  data() {
    return {};
  },
  computed: {
    ...mapState(["pageTitle"]),
  },
  watch: {
    $route(val) {
      this.setPageTiel();
    },
  },
  created() {
    console.log(this.$route);
    this.setPageTiel();
  },
  methods: {
    ...mapMutations(["modifyPageT", "setPageArray"]),
    setPageTiel() {
      this.setPageArray(this.$route.matched);
      // this.modifyPageT(this.$route.meta.pageTitle);
    },
    renturnPage() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.admin_page_title {
  flex-shrink: 0;
  display: flex;
  height: 50px;
  padding: 0 20px;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border-bottom: 1px solid #e4e4e4;
  // .page_title {
  //   position: relative;
  //   padding-left: 10px;
  //   font-size: 16px;
  //   font-weight: bold;
  //   color: #999999;
  //   &::before {
  //     position: absolute;
  //     content: "";
  //     width: 4px;
  //     height: 18px;
  //     top: 50%;
  //     left: 0;
  //     transform: translateY(-50%);
  //     background: #0079fe;
  //   }
  // }
}
</style>
