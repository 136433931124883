<template>
  <div class="edit_role">
    <a-drawer
      :title="editAccountId > -1 ? '编辑角色' : '新增角色'"
      :visible="visible"
      :width="650"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
    >
      <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="角色名称">
          <a-input v-model="role_name" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="角色权限">
          <div class="tree_area">
            <a-tree
              :treeData="treeData"
              checkable
              :replaceFields="replaceFields"
              defaultExpandAll
              v-model="select_menu_ids"
            ></a-tree>
          </div>
        </a-form-model-item>
        <a-form-model-item label="状态">
          <a-radio-group v-model="role_status">
            <a-radio :value="1"> 正常 </a-radio>
            <a-radio :value="0"> 禁用 </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>
      <div class="drawer_bottom_area">
        <a-button
          @click="
            () => {
              visible = false;
            }
          "
          >取消</a-button
        >
        <a-button type="primary" @click="submitForm" :loading="formLoading"
          >确定</a-button
        >
      </div>
    </a-drawer>
  </div>
</template>

<script>
// import { getMenusList } from "@/api/public";
import * as systemRun from "@/api/systemRun";
export default {
  name: "editRole",
  data() {
    return {
      formLoading: false,
      editAccountId: -1,
      visible: false,
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 19,
      },
      role_name: "",
      role_status: 1,
      select_menu_ids: [],
      replaceFields: {
        children: "abc", //js bug。当孩子属性使用children时，递归函数无法获取children数组的length
        title: "menu_name",
        key: "menu_id",
      },
      treeData: [],
    };
  },
  methods: {
    openNotification: function(type, msg) {
      this.$notification[type]({
        message: "系统提示",
        description: msg,
      });
    },
    submitForm() {
      if (this.role_name == "" || this.select_menu_ids.length == 0) {
        this.openNotification("error", "表单未填写完整");
        return;
      }
      this.formLoading = true;
      let parentsId = [];
      this.select_menu_ids.forEach((item, index) => {
        //此函数解决的问题是后端API数据与antd UI树形控件数据不兼容问题。
        this.treeData.forEach((el, elIndex) => {
          //注意：此函数最多仅支持两层菜单
          if (el.menu_id == item) {
            parentsId.push(item);
          } else {
            if (el.abc.findIndex((val) => val.menu_id == item) > -1) {
              parentsId.push(el.menu_id);
            }
          }
        });
      });
      let menu_list = [...new Set([...this.select_menu_ids, ...parentsId])];
      let menu_list_str = "";
      menu_list.forEach((item, index) => {
        menu_list_str += item + ",";
      });
      menu_list_str = menu_list_str.substring(0, menu_list_str.length - 1);

      let data = {
        name: this.role_name,
        menu_ids: menu_list_str,
        status: this.role_status,
        role_id: this.editAccountId === -1 ? undefined : this.editAccountId,
      };
      if (this.editAccountId == -1) {
        systemRun
          .addRole(data)
          .then((res) => {
            this.formLoading = false;
            this.visible = false;
            this.$emit("editItem", {});
            this.openNotification("success", res.data.errmsg);
          })
          .catch((res) => {
            this.openNotification("error", res.data.errmsg);
          });
      } else {
        systemRun
          .updateRole(data)
          .then((res) => {
            this.formLoading = false;
            this.visible = false;
            this.$emit("editItem", {});
            this.openNotification("success", res.data.errmsg);
          })
          .catch((res) => {
            this.openNotification("error", res.data.errmsg);
          });
      }
    },
    getPageListMenu() {
      getMenusList().then((res) => {
        this.treeData = res.data.data;
      });
    },
    showDrawer(id, data) {
      this.editAccountId = id;
      this.formLoading = false;
      if (id == -1) {
        // this.getPageListMenu();
        this.role_name = "";
        this.select_menu_ids = [];
        this.visible = true;
      } else {
        // systemRun.getMenusByRole(id).then((res) => {
        //   this.treeData = res.data.data;
        //   this.role_name = data.role_name;
        //   this.role_status = data.role_status;
        //   this.select_menu_ids = [];
        //   this.removeFather(this.treeData);
        //   console.log(this.select_menu_ids)
        //   this.visible = true;
        // })
        // .catch((res) => {
        //    this.openNotification("error", res.data.errmsg);
        // });
      }
    },
    removeFather(data) {
      // 从API从接受某角色权限树。一个父结点的N个孩子中若有一个孩子是选中的，则该父结点是选中的。但是andt树型结构则认为只要父元素选中，其子元素全部选中。因此为解决这一问题需要把已选中的子结点的父结点设为未选中。
      if (data.length == undefined) {
        //对象
        if (data.abc.length == 0) {
          if (data.is_check == 1) this.select_menu_ids.push(data.menu_id);
        } else {
          this.removeFather(data.abc);
        }
      } else {
        //数组
        let length = data.length;
        length--;
        if (length >= 0) {
          while (length >= 0) {
            this.removeFather(data[length--]);
          }
        }
      }
    },
    onClose() {
      this.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.drawer_bottom_area {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 10px 20px;
  text-align: right;
  z-index: 1;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(198, 198, 198, 0.67);
  button {
    margin-left: 10px;
  }
}
.tree_area {
  height: 400px;
  padding: 20px;
  border: 1px solid #e6e6e6;
  overflow-y: auto;
}
</style>
