<template>
  <div class="edit_account">
    <a-drawer
      :title="id > -1 ? '编辑账号' : '新增账号'"
      :visible="visible"
      :width="650"
      :body-style="{ paddingBottom: '80px' }"
      :destroyOnClose="true"
      @close="onClose"
    >
      <a-form-model
        ref="account_form"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="姓名" prop="admin_realname">
          <a-input
            v-model="form.admin_realname"
            allowClear
            placeholder="请输入"
          />
        </a-form-model-item>
        <a-form-model-item label="账号" prop="admin_name">
          <a-input v-model="form.admin_name" allowClear placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="密码" prop="admin_password">
          <a-input
            v-model="form.admin_password"
            allowClear
            placeholder="请输入"
          />
        </a-form-model-item>
        <a-form-model-item label="角色" prop="admin_role_id">
          <a-select
            allowClear
            show-search
            v-model="form.admin_role_id"
            optionFilterProp="children"
            placeholder="请选择角色"
          >
            <a-select-option
              v-for="item in roleList"
              :value="item.role_id"
              :key="item.role_id"
            >
              {{ item.role_name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="备注（选填）">
          <a-textarea
            placeholder="请输入备注"
            allowClear
            v-model="form.admin_remark"
            :rows="5"
          />
        </a-form-model-item>
        <a-form-model-item label="状态">
          <a-radio-group v-model="form.admin_status">
            <a-radio :value="1"> 正常 </a-radio>
            <a-radio :value="0"> 禁用 </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>

      <div class="drawer_bottom_area">
        <a-button
          @click="
            () => {
              visible = false;
            }
          "
          >取消</a-button
        >
        <a-button type="primary" @click="submitForm" :loading="subLoading"
          >确定</a-button
        >
      </div>
    </a-drawer>
  </div>
</template>

<script>
// import * as ajax from "@/api/account";
export default {
  name: "editAccount",
  data() {
    return {
      id: -1,
      visible: false,
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 19,
      },
      form: {},
      subLoading: false,
      roleList: [],
      rules: {
        admin_realname: [
          {
            required: true,
            message: "请填写完整",
            trigger: "blur",
          },
        ],
        admin_name: [
          {
            required: true,
            message: "请填写完整",
            trigger: "blur",
          },
        ],
        admin_role_id: [
          {
            required: true,
            message: "请选择角色",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    getAllRoleList() {
      ajax.getRolesList().then((res) => {
        this.roleList = res.data.data.list;
      });
    },
    showDrawer(id) {
      this.getAllRoleList();
      this.id = id;
      this.subLoading = false;
      if (id == -1) {
        this.form = {
          admin_realname: "",
          admin_name: "",
          admin_password: "",
          admin_role_id: undefined,
          admin_remark: "",
          admin_status: 1,
        };
        this.visible = true;
      } else {
        ajax
          .findOneAccount({
            admin_id: id,
          })
          .then((res) => {
            this.form = res.data.data;
            this.visible = true;
          })
          .catch((res) => {
            this.openNotification("error", res.data.errmsg);
          });
      }
    },
    onClose() {
      this.visible = false;
    },
    submitForm() {
      this.$refs.account_form.validate((valid) => {
        if (valid) {
          let api = this.id === -1 ? ajax.addAccount : ajax.updateAccount;
          this.subLoading = true;
          api({
            ...this.form,
          })
            .then((res) => {
              this.subLoading = false;
              this.visible = false;
              this.$emit("searchList");
              this.openNotification("success", res.data.errmsg);
            })
            .catch((res) => {
              this.subLoading = false;
              this.openNotification("error", res.data.errmsg);
            });
        }
      });
    },
    openNotification: function(type, msg) {
      this.$notification[type]({
        message: "系统提示",
        description: msg,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.drawer_bottom_area {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 10px 20px;
  text-align: right;

  z-index: 1;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(198, 198, 198, 0.67);
  button {
    margin-left: 10px;
  }
}
</style>
