<template>
  <div class="add-plan-wrap">
    <div class="pageHeader">
      <span>新增预案</span>
      <div class="pageHeaderBtns">
        <a-button type="primary" class="leftBtn" @click="publishMsg(1)" :loading="publishLoading">发布</a-button>
        <a-button @click="backTo">返回</a-button>
      </div>
    </div>
    <div class="pageContent">
      <a-form-model ref="ruleForm" :model="formData" :rules="rules">
        <a-form-model-item label="标题" prop="title" :labelCol="{span: 2, offset: 0}" :wrapperCol="{span: 8, offset: 0}" labelAlign="left">
          <a-input v-model="formData.headline" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item label="创建人" prop="creator" :labelCol="{span: 2, offset: 0}" :wrapperCol="{span: 8, offset: 0}" labelAlign="left">
          <a-input v-model="formData.creator" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item label="发布部门" prop="issueSection" :labelCol="{span: 2, offset: 0}" :wrapperCol="{span: 8, offset: 0}" labelAlign="left">
          <a-input v-model="formData.issueSection" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item label="创建时间" prop="title" :labelCol="{span: 2, offset: 0}" :wrapperCol="{span: 8, offset: 0}" labelAlign="left">
          <a-date-picker style="width:100%"  v-model="formData.creationTime" format="YYYY/MM/DD"/>

        </a-form-model-item>
        <Editor @setEditContent="setEditContent" :editorData="editorData" />
        <a-form-model-item label="附件" :labelCol="{span: 2, offset: 0}" :wrapperCol="{span: 11, offset: 0}" labelAlign="left">
        </a-form-model-item>
        <a-form-model-item label="" :labelCol="{span: 2, offset: 0}" :wrapperCol="{span: 11, offset: 0}" labelAlign="left">
          <UploadFile @setFile="setFile" :fileList="fileList" />
          <span class="uploadRemark">支持扩展名：.rar .zip .doc .docx .pdf .jpg...</span>
        </a-form-model-item>
        <!-- <a-form-model-item label="是否下发" :labelCol="{span: 2, offset: 0}" :wrapperCol="{span: 11, offset: 0}" labelAlign="left">
          <a-radio-group v-model="formData.issueSchool">
            <a-radio :value="1">是</a-radio>
            <a-radio :value="0">否</a-radio>
          </a-radio-group>
        </a-form-model-item> -->
      </a-form-model>
    </div>
  </div>
</template>

<script>
import Editor from '@/component/editorArea/editorArea'
import UploadFile from '@/component/uploadFile/uploadFile'
import * as api from "@/api/planMan";
export default {
  name: "newsInfo",
  data() {
    return {
      defaultFileList: [],
      fileOption: {
        showPreviewIcon: true,
        showRemoveIcon: false
      },
      editorData: '',
      formData: {
        headline: '',//标题
        creator: '',//创建人
        issueSection: '',//发布部门
        creationTime: '',//创建时间
        content: '',//内容
        accessoryUrl: '',//附件
        issueSchool: 0,//是否下发,默认0,为1时下发学校
      },
      fileList: [],
      schoolList: [],
      rules: {
        newsTitle: [{ require: true, message: '请输入信息标题', trigger: 'blur' }],
      },
      pageType: 1,
      newsId: '',
      publishLoading: false
    };
  },
  components: {
    Editor,
    UploadFile
  },
  created() {
  },
  methods: {
    backTo() {
      this.$router.go(-1)
    },
    setEditContent(val) {
      this.editorData = val
    },
    setFile(val) {
      this.fileList = val
    },
    publishMsg(type) {
      this.publishLoading = true
      let fileUrls = [], fileNames = []
      this.fileList.map(item => {
        fileUrls.push(item.url)
        fileNames.push(item.name)
      })
      let creationTime=''
      if (this.formData.creationTime) {
        creationTime = this.moment(this.formData.creationTime).format('YYYY/MM/DD')
      }
      let data = {
        ...this.formData,
        creationTime,
        accessoryUrl: fileUrls.join(','),
        content: this.editorData
      }
      api.epidemicPlanManageSave(data).then(res => {
        if (res.data.code == 200) {
          this.openNotification('success', res.message)
          this.publishLoading = false
          this.backTo()
        } else {
          this.openNotification('error', res.message)
        }
      })
    },
    openNotification: function (type, msg) {
      this.$notification[type]({
        message: "系统提示",
        description: msg,
      });
    },
  },
};
</script>

<style lang="less">
.add-plan-wrap {
  .pageHeader {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    color: rgba(16, 16, 16, 100);
    background: white;
    text-indent: 27px;
    justify-content: space-between;
    span {
      font-weight: bold;
      margin-right: 10px;
    }
    .pageHeaderBtns {
      margin-right: 27px;
      .leftBtn {
        margin-right: 10px;
      }
    }
  }

  .pageContent {
    margin-top: 20px;
    background: white;
    padding: 27px;
  }
  .uploadRemark {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    font-family: PingFangSC-regular;
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
}
</style>
