<template>
  <div class="deviceBill">
    <div class="pageInfo">
      <div class="pageInfo_item">
        <div class="pageInfo_item_title">本月报修</div>
        <div class="pageInfo_item_value">{{ pageInfo.thisMonth }}</div>
      </div>
      <div class="pageInfo_item">
        <div class="pageInfo_item_title">累计报修</div>
        <div class="pageInfo_item_value">{{ pageInfo.totalRepair }}</div>
      </div>
      <div class="pageInfo_item">
        <div class="pageInfo_item_title">已修复</div>
        <div class="pageInfo_item_value green">{{ pageInfo.fixed }}</div>
      </div>
      <div class="pageInfo_item">
        <div class="pageInfo_item_title">修复中</div>
        <div class="pageInfo_item_value red">{{ pageInfo.onRepair }}</div>
      </div>
    </div>
    <div class="pageSearch">
      <a-form-model :model="searchForm" layout="inline">
        <a-form-model-item label="报修时间：">
          <a-date-picker v-model="searchForm.repairTime" format="YYYY-MM-DD" />
        </a-form-model-item>
        <a-form-model-item label="维修状态：">
          <a-select
            v-model="searchForm.repairStatus"
            class="searchSelect"
            allowClear
            placeholder="请选择"
          >
            <a-select-option :value="2">已修复</a-select-option>
            <a-select-option :value="1">修复中</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="设备名称：">
          <a-input
            v-model="searchForm.deviceName"
            placeholder="请输入"
            class="searchSelect"
          />
        </a-form-model-item>
        <a-form-model-item label="设备编码：">
          <a-input
            v-model="searchForm.deviceCode"
            placeholder="请输入"
            class="searchSelect"
          />
        </a-form-model-item>
        <a-form-model-item label="审批编号：">
          <a-input
            v-model="searchForm.approvalNo"
            placeholder="请输入"
            class="searchSelect"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" class="searchBtn" @click="search"
            >查询</a-button
          >
          <a-button class="searchBtn" @click="reset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="pageContent">
      <div class="pageContent_head">
        <div class="pageContent_title">报修记录</div>
        <a-button type="primary" @click="openAddRepair">新增</a-button>
      </div>
      <div class="pageContent_table">
        <a-table
          :columns="columns"
          :data-source="dataSource"
          :loading="tableLoading"
          size="middle"
          :pagination="pagination"
        >
          <div slot="repairStatus" slot-scope="text, data, index">
            <span :class="data.repairStatus == 2 ? 'green' : 'red'">
              {{ data.repairStatus == 2 ? "已修复" : "修复中" }}
            </span>
          </div>
          <div slot="actions" slot-scope="text, data, index">
            <a-button type="link" @click="toInfo(data.id)">详情</a-button>
          </div>
        </a-table>
      </div>
    </div>
    <RepairInfo ref="repairInfo" :reportId="reportId" />
    <AddRepair
      ref="addRepair"
      :deviceCode="deviceCode"
      @refreshTable="refreshTable"
    />
  </div>
</template>

<script>
import RepairInfo from "./components/repairInfo";
import AddRepair from "./components/addRepair";
import { deviceReportStatistics, deviceReportList } from "@/api/deviceManage";
import moment from "moment";
export default {
  name: "deviceList",
  data() {
    return {
      tableLoading: false,
      pagination: {
        current: 1,
        showTotal: (total, range) => `第${range.join("-")}条/总共${total}条`,
        onChange: (page, pageSize) => this.changePage(page, pageSize),
        onShowSizeChange: (current, size) => this.showSizeChange(current, size),
        total: 0,
        pageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
      },
      pageInfo: {
        thisMonth: 0,
        totalRepair: 0,
        fixed: 0,
        onRepair: 0,
      },
      searchForm: {
        repairTime: "",
        repairStatus: undefined,
        deviceName: "",
        deviceCode: "",
        approvalNo: "",
      },
      columns: [
        {
          title: "审批编号",
          dataIndex: "reportNo",
          align: "center",
        },
        {
          title: "设备名称",
          dataIndex: "deviceName",
          align: "center",
        },
        {
          title: "设备编码",
          dataIndex: "deviceCode",
          align: "center",
        },
        {
          title: "所属学校",
          dataIndex: "schoolName",
          align: "center",
        },
        {
          title: "设备位置",
          dataIndex: "deviceAddress",
          align: "center",
        },
        {
          title: "报修人",
          dataIndex: "userName",
          align: "center",
        },
        {
          title: "报修时间",
          dataIndex: "reportTime",
          align: "center",
        },
        {
          title: "维修状态",
          align: "center",
          scopedSlots: {
            customRender: "repairStatus",
          },
        },
        {
          title: "操作",
          align: "center",
          scopedSlots: {
            customRender: "actions",
          },
        },
      ],
      dataSource: [],
      deviceTypeSelected: 1,
      deviceCode: "",
      reportId: -1,
    };
  },
  components: {
    RepairInfo,
    AddRepair,
  },
  created() {
    this.getHead();
    this.getList();
  },
  methods: {
    moment,
    search() {
      this.pagination.current = 1;
      this.getList();
    },
    reset() {
      this.searchForm = {
        repairTime: "",
        repairStatus: "",
        deviceName: "",
        deviceCode: "",
        approvalNo: "",
      };
    },
    refreshTable() {
      this.getList();
    },
    toInfo(id) {
      this.reportId = id;
      this.$nextTick(() => {
        this.$refs.repairInfo.showModal();
      });
    },
    openAddRepair() {
      this.$refs.addRepair.showModal();
    },
    getList() {
      let data = {
        deviceCode: this.searchForm.deviceCode,
        deviceName: this.searchForm.deviceName,
        page: this.pagination.current,
        pageSize: this.pagination.pageSize,
        repairStatus: this.searchForm.repairStatus,
        reportDate:
          this.searchForm.repairTime == ""
            ? ""
            : moment(this.searchForm.repairTime).format("YYYY-MM-DD"),
        reportNo: this.searchForm.approvalNo,
      };
      deviceReportList(data).then((res) => {
        this.dataSource = res.data.data.list;
        this.pagination.total = res.data.data.total;
      });
    },
    getHead() {
      let data = {};
      deviceReportStatistics(data).then((res) => {
        this.pageInfo = {
          thisMonth: res.data.data.countThisMonth,
          totalRepair: res.data.data.countTotal,
          fixed: res.data.data.countRepaired,
          onRepair: res.data.data.countRepairing,
        };
      });
    },
  },
};
</script>

<style lang="less" scoped>
.deviceBill {
  width: 100%;
  height: 100%;
}
.pageHeader {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  color: rgba(16, 16, 16, 100);
  background: white;
  text-indent: 27px;
  span {
    font-weight: bold;
    margin-right: 10px;
  }
}
.pageInfo {
  margin-bottom: 10px;
  width: 100%;
  height: 101px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: white;
}
.pageInfo_item {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  color: rgba(16, 16, 16, 100);
}
.pageSearch {
  margin-bottom: 20px;
  background: white;
  padding: 20px;
}
.searchBtn {
  margin-right: 10px;
}
.searchSelect {
  width: 200px;
}
.pageContent_title {
  height: 20px;
  display: flex;
  align-items: center;
  margin-left: 27px;
  position: relative;
  color: rgba(16, 16, 16, 100);
  font-weight: bold;
}
.pageContent_title::before {
  width: 4px;
  height: 20px;
  left: -7px;
  top: 0;
  position: absolute;
  content: "";
  background-color: #555555;
}
.lessRed {
  color: #c71414;
}
.pageContent_table {
  background-color: white;
  margin-top: 20px;
}
.green {
  color: #62b968;
}
.red {
  color: #f5b386;
}
.pageContent_head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
