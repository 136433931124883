import { fetchEndpoint } from "@/server/network1";
import axios from "axios";


//预案列表分页数据
export const getPageList = (data) => fetchEndpoint(
    "/school/epidemicPlanManage/getPageList", { ...data }, true, 'POST'
)
//预案下拉框
export const getPlanSource = (data) => fetchEndpoint(
    "/school/epidemicPlanManage/getPlanSource", { ...data }, true, 'POST'
)
//新增预案
export const epidemicPlanManageSave = (data) => fetchEndpoint(
    "/school/epidemicPlanManage/epidemicPlanManageSave", { ...data }, true, 'POST'
)
//查看详情
export const getEpidemicPlanManageById = (data) => fetchEndpoint(
    "/school/epidemicPlanManage/getEpidemicPlanManageById", { ...data }, true, 'POST'
)