const accountAdminColumns = [
  { title: '序号', align: 'center', dataIndex: 'indexNumber' },
  { title: '姓名', align: 'center', dataIndex: 'admin_realname' },
  { title: '账号', align: 'center', dataIndex: 'admin_name' },
  { title: '备注', align: 'center', dataIndex: 'admin_remark' },
  { title: '角色', align: 'center', dataIndex: 'role_name' },
  {
    title: '状态',
    align: 'center',
    scopedSlots: {
      customRender: 'admin_status'
    }
  },
  {
    title: '操作',
    align: 'center',
    scopedSlots: {
      customRender: 'action'
    }
  }
]
const roleAdminColumns = [
  { title: '序号', align: 'center', dataIndex: 'indexNumber' },
  { title: '角色名称', align: 'center', dataIndex: 'role_name' },
  {
    title: '状态',
    align: 'center',
    scopedSlots: {
      customRender: 'status'
    }
  },
  {
    title: '操作',
    align: 'center',
    scopedSlots: {
      customRender: 'action'
    }
  }
]
export { accountAdminColumns, roleAdminColumns }
