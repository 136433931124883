<template>
  <a-row :gutter="10">
    <a-col :span="5">
      <a-card class="left">
        <school-tree @addBack="setDepart" @onSelect="onSelect"></school-tree>
      </a-card>
    </a-col>
    <a-col :span="19">
      <a-card class="right">
        <hkVideo ref="hikVideo" :objData="objData" :cameraIndexCode="cameraIndexCode"></hkVideo>
      </a-card>
    </a-col>
  </a-row>
</template>
<!-- <script type="module" src="webrtc-streamer-element.js"></script> -->

<script>
import SchoolTree from "./SchoolTree";
import hkVideo from "./hikVideo.vue";
// import hkVideo from "./VideoPlay";
import { getHkConfig } from "@/api/video.js";


export default {
  name: "VedeoSurveillance",
  components: {
    SchoolTree,
    hkVideo,
  },
  data() {
    return {
      cameraIndexCode: "",
      webRtcServer: null, //webRtcServer上下文
      objData: {
        //海康初始化数据
        appkey: "",
        ip: "60.12.105.252",
        secret: "",
        port: 4433,
        playMode: 0, // 0 预览 1回放
        layout: "2x2", //页面展示的模块数【16】
      },
      key: 1,
      videoUrl: "",
      //视频监控
      queryParam: {
        page: 1,
        pageSize: 10,
        type: 1,
      },
    };
  },
  beforeDestroy() {
    this.$refs.hikVideo.onDestroy()
  },

  computed: {},
  created() {
    this.getConfig();
  },

  methods: {

    getConfig() {
      getHkConfig().then((res) => {
        if (res.code == 200) {
          if (res.data.appKey && res.data.appSecret) {
            this.objData.appkey = res.data.appKey;
            this.objData.secret = res.data.appSecret;
            this.$refs.hikVideo.initPlugin()
          }
        }
      })

    },
    loadVideo() {
      // let res = await this.$store.dispatch("previewVideo", {
      //   code: data.schoolId,
      // });
      // if (res.code == 200) {
      //   this.videoUrl = res.result;
      console.log(this.queryParam.schoolId, "this.queryParam.schoolId");

      this.$refs.hikVideo.previewVideo(this.queryParam.schoolId);


      // }
    },
    // 设置初始值
    setDepart(val) {
      console.log("setDepart", val);
      // this.loadVideo(val.indexCode);
    },
    // 切换树
    onSelect(val) {
      this.queryParam.schoolId = val.deptId;
      console.log(val, 'val');

      if (val.isLeaf) {
        this.cameraIndexCode = val.deptId
        this.loadVideo(this.queryParam);
      } else {
        this.cameraIndexCode = ''
        this.$refs.hikVideo.stopAllPreview();

      }




      // this.loadList({}); //视频监控列表更新
      // this.$nextTick(() => {
      //   this.$refs.VedioPolling.stop() // 暂停播放
      // })
      // if (this.$refs.VedioPolling) {
      //   this.$refs.VedioPolling.stop(); // 暂停播放
      // }
      // }else{
      //   this.$nextTick(() => {
      //     this.$refs.VedioPolling.changeSchool(this.queryParam.schoolId)
      //   })
      // }
    },
  },
};
</script>

<style scoped>
.left {
  min-height: 85vh;
}

.right {
  min-height: 85vh;
}
</style>