<template>
  <div class="clearfix">
    <!--       -->
    <a-upload
      listType="picture-card"
      :action="uploadUrl"
      :fileList="fileListList"
      @preview="handlePreview"
      @change="handleChange"
      :headers="headers"
      accept="image/jpeg,image/png"
    >
      <div v-if="imageUrl.length < maxNum">
        <a-icon type="plus" />
        <div class="ant-upload-text">点击上传</div>
      </div>
    </a-upload>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
/*
  用于图片上传

  引入 import UploadImage from "@/component/uploadImage/uploadImage";
  使用 <UploadImage @setImage="setImage" :maxNum="4" :imgList="imageUrl"/>
  setImage 组件返回的值 imageUrl 显示的图片数组 maxNum最多显示数量
  data(){
    return{
      imageUrl:[]
    }
  }
  methods:{
    setImage(val){
      this.imageUrl=val
    }
  }
*/
import { API_HOST } from "@/server/network1";

export default {
  name: "UpdataImage",
  data() {
    return {
      fileListList: [],
      imageUrl: [],
      previewVisible: false,
      previewImage: "",
      uploadUrl: API_HOST + "/school/goApply/upload",
      API_HOST,
    };
  },
  props: {
    maxNum: {
      type: Number,
      default: 1,
    },
    imgList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  computed: {
    headers() {
      return {
        Authorization: localStorage.getItem("jx_token"),
        zjUnicomToken: localStorage.getItem("jx_token1")
          ? localStorage.getItem("jx_token1")
          : "",
      };
    },
  },
  created() {},
  watch: {
    imgList: {
      handler(value, oldValue) {
        let that = this;
        let array = [];
        if (value.indexOf("") == -1) {
          value.forEach((item, index) => {
            let json = {
              uid: index + 1,
              name: `image${index}`,
              status: "done",
              url: item || item.url || item.thumbUrl || "",
            };
            array.push(json);
          });
          that.fileListList = array;
          this.imageUrl = value;
        }
      },
    },
  },
  components: {},
  mounted() {},
  methods: {
    handleCancel() {
      this.previewVisible = false;
    },
    handlePreview(file) {
      this.previewImage = file.url || file.preview || file.thumbUrl;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      this.fileListList = fileList;
      console.log(fileList)
      this.$nextTick(() => {
        let imgPic = fileList.map((item) => {
          return item.url ? item.url : item.response ? item.response.data.url : "";
        });
        this.imageUrl = imgPic;
        this.$emit("setImage", imgPic);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.clearfix {
  display: flex;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
