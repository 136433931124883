<!--
 * @Descripttion: 
 * @version: v1.0
 * @Author: Welkin
 * @Date: 2021-05-22 11:08:07
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-06 14:13:26
-->
<template>
  <div>

    <a-row :gutter="10">
      <a-col :span="24">
        <a-card class="right">
          <a-tabs v-model="key" @change="callback">
            <a-tab-pane tab="视频监控" :key="1" forceRender>
              <Real-Time
                ref="RealTime"
                @changePage="changePage"
                @search="search"
              ></Real-Time>
            </a-tab-pane>
            <a-tab-pane tab="监控轮巡" :key="2">
              <Vedio-Polling ref="VedioPolling"></Vedio-Polling>
            </a-tab-pane>
          </a-tabs>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import VedioPolling from "./modules/VedioPolling";
import { mixinPagination } from "@/utils/mixin.js";
import RealTime from "./modules/RealTime";
import { deviceHead, deviceList } from "@/api/video";
export default {
  name: "VedeoSurveillance",
  components: {
    mixins: [mixinPagination],
    VedioPolling,
    RealTime,
  },
  data() {
    return {
      key: 1,
      //视频监控
      queryParam: {
        page: 1,
        pageSize: 10,
        type: 1,
      },
      deviceNum: {
        off: 0,
        on: 0,
        total: 0,
      },
    };
  },
  computed: {},
  created() {
    // this.getdeviceHead();
    this.loadList({})
  },
  methods: {
    //设备列表头部
    getdeviceHead: function () {
      // let res = await this.$store.dispatch("deviceHead");
      // if (res.code == 200) {
      //   this.deviceNum.off = res.result.off;
      //   this.deviceNum.on = res.result.on;
      //   this.deviceNum.total = res.result.total;
      // }
      deviceHead()
        .then((res) => {
          if (res.code == 200) {
            this.deviceNum.off = res.result.off;
            this.deviceNum.on = res.result.on;
            this.deviceNum.total = res.result.total;
          }
        })
        .catch((err) => {});
    },
    //视频监控列表
    loadList: function (parameter) {
      // let res = await this.$store.dispatch(
      //   "deviceList",
      //   Object.assign(parameter, this.queryParam)
      // );
      deviceList(Object.assign(parameter, this.queryParam)).then((res) => {
        if (res.code == 200) {
          console.log("111111111", this.$refs.RealTime);
          this.$refs.RealTime.setVedioList(res);
        }
      });
    },

    //从子组件传过来的值
    changePage(pagination) {
      console.log("pagination:", pagination);
      this.queryParam.page = pagination.current;
      this.queryParam.pageSize = pagination.pageSize;
      this.loadList({});
    },
    //搜索
    search(queryParam) {
      this.queryParam.page = queryParam.page;
      this.queryParam.deviceId = queryParam.deviceId;
      this.queryParam.deviceName = queryParam.deviceName;
      this.queryParam.type = queryParam.type;
      this.loadList({});
    },
    //重置
    // reset(queryParam){
    //   this.queryParam.deviceId = queryParam.deviceId
    //   this.queryParam.deviceName = queryParam.deviceName
    //   this.queryParam.type = queryParam.type
    //   this.loadList({})
    // },
    // 设置初始值
    // 切换树

    callback(key) {
      if (key == 1) {
        this.key = key;
        console.log("callback", key);
        this.loadList(this.queryParam); //刷新列表
        this.$nextTick(() => {
          this.$refs.VedioPolling.stop(); // 暂停播放
        });
      } else {
        this.key = key;
        console.log("callback", key);
        console.log("schoolId", this.queryParam.schoolId);
        this.$nextTick(() => {
          this.$refs.VedioPolling.changeSchool(this.queryParam.schoolId);
        });
      }
    },
    //跳转到异常学校列表
    async pushToOffList() {
      this.$router.push({ path: "/VedeoSurveillanceDetail" });
    },
  },
};
</script>
<style scoped>
/* @import "~@assets/less/common.less"; */
.left {
  min-height: 85vh;
}
.right {
  min-height: 85vh;
}
</style>