//主线 路由
import { masterRouterMap } from "./master";
import { otherRouterMap } from "./zxj";
import { planRouterMap } from "./plan";
import { threeProofingRouter } from "./threeProofing";
import { addressBookRouter } from "./addressBook";

export const collectionRouterMap = [
  ...masterRouterMap,
  ...otherRouterMap,
  ...planRouterMap,
  ...threeProofingRouter,
  ...addressBookRouter,
];
