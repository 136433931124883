<!--
 * @Descripttion: 
 * @version: v1.0
 * @Author: Welkin
 * @Date: 2021-05-22 16:20:06
 * @LastEditors: Welkin
 * @LastEditTime: 2021-06-04 15:05:44
-->
<!--
 * @Descripttion: 
 * @version: v1.0
 * @Author: Welkin
 * @Date: 2021-05-22 11:12:15
 * @LastEditors: Welkin
 * @LastEditTime: 2021-05-22 15:50:35
-->
<template>
<div>
  <a-card title="监控列表 " >
    <a-button :style="{ marginLeft: '28px' }" type="primary" ghost slot="extra" @click="sync">同步平台</a-button>
    <!-- 列表 -->
      <div class="table-page-search-wrapper">
        <a-form :model="queryParam"  @keyup.enter.native="searchQuery" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-row>
            <a-col :span="6">
              <a-form-item label="监控类别">
                <a-select v-model="queryParam.type" placeholder="请选择">
                  <a-select-option v-for="d in options" :key="d.id" :value="d.id">{{ d.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="设备ID">
                <a-input placeholder="请输入设备ID" v-model="queryParam.deviceId"></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="设备名称">
                <a-input placeholder="请输入设备名称" v-model="queryParam.deviceName"></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <span style="float: left;overflow: hidden;margin-top:4px" class="table-page-search-submitButtons">
                <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
                <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
        <br/>
        <a-table
          bordered
          :columns="columns"
          :dataSource="vedioList"
          :rowKey="(record) => record.deviceId"
          :pagination="pagination"
          @change="handleTableChange"
          >
            <span slot="action" slot-scope="text, record">
              <a @click="handleView(record)">查看</a>
            </span>
        </a-table>
      </div>
      
  </a-card>
  </div>
</template>

<script>
import VueAliplayerV2 from 'vue-aliplayer-v2';
import {
  sync,getDicDropDown
  
} from '@/api/video'
export default {
  name: 'RealTime',
  components: {
    VueAliplayerV2:VueAliplayerV2.Player
  },
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 },
      },
      queryParam: {
        type: 1
      },
      options: {},
      vedioList : [],
      columns:[
        {
          title: '设备ID',
          dataIndex: 'deviceId',
          key: 'deviceId',
          align:'center'
        },
        {
          title: '设备名称',
          dataIndex: 'deviceName',
          key: 'deviceName',
          align:'center'
        },
        {
          title: '设备IP',
          dataIndex: 'deviceIp',
          key: 'deviceIp',
          ellipsis: true,
          align:'center'
        },
        {
          title: '设备类别',
          dataIndex: 'type',
          key: 'type',
          align:'center'
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          align: 'center',
        },
      ],
      pagination: {
        current:1,
        total: 0,
        pageSize: 10,//每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"],//每页中显示的数据
        showTotal: total => `共有 ${total} 条数据`,  //分页中显示总的数据
      },
    }
  },
  computed: {
    
  },
  
  created (){
     this.getDicDropDown({id:12})
  },
  methods: {
    //监控类别数据字典
    getDicDropDown: async function (parameter) {
      // let res = await this.$store.dispatch(
      //   "getDicDropDown",
      //   Object.assign(parameter)
      // );
      // if (res.code == 200) {
      //   this.options = res.result
      // }
      getDicDropDown(Object.assign(parameter)).then(res=>{
       if (res.code == 200) {
        this.options = res.result
      }
      })
    },
    //同步平台
    sync: async function (parameter) {
      // let res = await this.$store.dispatch(
      //   "sync",
      //   Object.assign(parameter)
      // );
      // if (res.code == 200) {
      // }
      sync(Object.assign(parameter)).then(res=>{
        if(res.data.code==200){
          this.$message.success("同步成功")
        }
      })
      
    },
    //从父组件传过来的值
    setVedioList(res){
      // console.log('vedioList',res)
      this.vedioList = res.result.list
      this.pagination.total= res.result.total
    },
    //分页
    handleTableChange(pagination) {
      // console.log('pagination:',pagination)
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.$emit('changePage',this.pagination)
      
    },
    // radioChange(value){
    //   console.log('value:',value)
    // },
    // 预警时间
    // onChange(date, dateString) {
    //   console.log(date, dateString);
    // },
    // 查看
    handleView(record){
      console.log('record',record)
      this.$router.push({path:"/VedeoDetail",query:{record:record} })
      sessionStorage.setItem('channelTitle',record.deviceName)
      sessionStorage.setItem('channelDeviceId',record.deviceId)
    },
    searchQuery(){
      this.pagination.current = 1
      this.queryParam.page = 1
      console.log('submit!', this.queryParam);
      this.$emit('search',this.queryParam)
    },
    searchReset(){
      this.queryParam.page = 1
      this.pagination.current = 1
      this.queryParam.deviceId = ''
      this.queryParam.deviceName =''
      this.queryParam.type = 1
      this.$emit('search',this.queryParam)
    }

  },
}
</script>
<style scoped>
/* @import '~@assets/less/common.less'; */
</style>