<template>
  <a-spin :spinning="confirmLoading">
    <div class="treeBoxContainer">
      <div class="treeBox">
        <h1 class="area">学校列表</h1>
        <a-input-search style="margin-bottom: 8px" v-model="searchValue" @search="onChange" placeholder="请输入位置名称" />
        <a-tree :treeData="treeData" @select="onSelect" :key="key" :expandedKeys="expandedKeys"
          :selectedKeys="selectedKeys" :autoExpandParent="autoExpandParent" @expand="onExpand" show-icon
          :style="{ height: screenHeight + 'px', overflowY: 'scroll' }">
          <!-- <span slot="custom" slot-scope="item">
            <span v-if="item.dataRef.title.indexOf(searchValue) > -1">
              {{
                item.dataRef.title.substr(
                  0,
                  item.dataRef.title.indexOf(searchValue)
                )
              }}
              <span style="color: #f50">{{ searchValue }}</span>
              {{
                item.dataRef.title.substr(
                  item.dataRef.title.indexOf(searchValue) + searchValue.length
                )
              }}
            </span> -->
          <template slot="custom" slot-scope="{ title }">
            <span v-if="title.indexOf(searchValue) > -1">
              {{ title.substr(0, title.indexOf(searchValue)) }}
              <span style="color: #f50">{{ searchValue }}</span>
              {{ title.substr(title.indexOf(searchValue) + searchValue.length) }}
            </span>
            <span v-else>{{ title }}</span>
          </template>
          <!-- <span>{{ item.dataRef.title }}</span> -->
          </span>
        </a-tree>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { getHkTree } from "@/api/video.js";


export default {
  name: "departTree",
  data() {
    return {
      searchStr: "",
      confirmLoading: false,
      labelCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 5,
        },
      },
      wrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 16,
        },
      },
      isshow: false,
      departNameList: [],
      model: {},
      searchValue: "",
      autoExpandParent: true,
      treeData: [],
      data: [],
      queryParam: {
        deptId: "",
      },
      screenHeight: "",
      key: 0,
      expandedKeys: [],
      selectedKeys: [],
      screenWidth: "",
    };
  },
  created() {
    this.loadDepartTree();
    this.screenHeight = 0.5 * window.screen.height;
    this.screenWidth = 0.26 * window.screen.width;
  },
  activated() { },
  mounted() { },
  watch: {
    //监听userArr，同步userSearchListOptions的checked状态
  },
  methods: {
    //搜索区域信息
    onChange(e) {
      // if(e){
      // const value = e
      if (e == "") {
        this.loadDepartTree();
      }

      var data = this.filter(this.treeData, e);
      if (data.length == 0) {
        this.loadDepartTree();
        this.$message.error("暂无匹配数据");
      } else {
        this.treeData = data;

        //  this.onExpand( this.treeData[0].key)
      }
      console.log(this.treeData);

      // let data=this.treeData
      //  this.treeData= this.travelWidelySearch(data)
      // const expandedKeys = this.data
      // // const value = e.target.value;
      // // const expandedKeys = this.data
      //   .map((item) => {
      //     if (item.title.indexOf(value) > -1) {
      //       return this.getParentKey(item.key, this.treeData);
      //     }
      //     return null;
      //   })
      //   .filter((item, i, self) => item && self.indexOf(item) === i);
      // this.onExpand(expandedKeys);
      // Object.assign(this, {
      //   expandedKeys,
      //   searchValue: value,
      //   autoExpandParent: true,
      // });
      // }else{
      //   this.expandedKeys=[1]
      //   this.searchValue=''
      // }
    },
    //搜索处理数据
    generateList(data) {
      for (let i = 0; i < data.length; i++) {
        const node = data[i];
        const key = node.key;
        if (this.searchValue == "node.title ") {
          return;
        }
        this.data.push({ key, title: node.title });
        if (node.children) {
          this.generateList(node.children);
        }
      }
    },
    //展开父节点
    getParentKey(key, tree) {
      let parentKey;
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
          if (node.children.some((item) => item.key === key)) {
            parentKey = node.key;
          } else if (this.getParentKey(key, node.children)) {
            parentKey = this.getParentKey(key, node.children);
          }
        }
      }
      return parentKey;
    },
    //搜索查询树
    filter(tree, str) {
      const newTree = [];
      tree.forEach((val) => {
        if (~val.title.indexOf(str) && val.children.length == 0) {
          console.log(val, "val");
          newTree.push(val);
        } else {
          const children = this.filter(val.children || [], str);
          if (children.length) {
            newTree.push({ ...val, children });
          }
        }
      });
      return newTree;
    },
    //处理区域树数据
    travelWidely(data) {
      let item = [];
      data.map((list, i) => {
        let newData = {};
        // if (list.id == 1) {
        //   newData.disabled = true;
        // }
        newData.key = list.indexCode;
        newData.value = list.indexCode;
        newData.title = list.name;
        newData.isLeaf = list.isLeaf;
        newData.scopedSlots = {
          title: "custom",
        };
        newData.children = list.child ? this.travelWidely(list.child) : []; //如果还有子集，就再次调用自己
        item.push(newData);
      });
      return item;
    },
    onExpand(expandedKeys) {
      //用户点击展开时，取消自动展开效果
      this.expandedKeys = expandedKeys;

      this.autoExpandParent = false;
    },
    //
    //Tree控件选择时的事件函数
    onSelect(selectedKeys, info) {
      console.log(info.node.dataRef, "info");
      this.isshow = false;
      this.selectedKeys = selectedKeys;
      this.queryParam.departName = info.node.dataRef.title;
      // console.log(selectedKeys[0], info.node.isLeaf);
      if (info.selected) {
        this.$emit("onSelect", {
          deptId: selectedKeys[0],
          parentName: info.node.dataRef.title || info.node.dataRef.title,
          isLeaf: info.node.dataRef.isLeaf
        });
      }
    },

    //获取整棵树
    loadDepartTree: async function () {
      getHkTree().then((res) => {
        if (res.code == 200) {
          if (res.data) {
            this.treeData = this.travelWidely([res.data]);
            this.generateList(this.treeData);
            let departList = res.data.child[0];
            console.log(this.treeData, "this.treeData");

            this.$emit("addBack", {
              treeData: departList,
            });
            //tree件默认展开第一级别下的部门
            this.expandedKeys.push(departList.indexCode);
            this.selectedKeys = [departList.indexCode];
            this.queryParam.departName = departList.name;
          }
        }
      })

    },
  },
};
</script>

<style lang="less" scoped>
.treeBoxContainer {
  width: 100%;
  padding-right: 10px;

  .treeBox {
    padding-top: 8px;
    padding-left: 10px;

    // border-radius: 14px;
    font-size: 24px;
    color: #595959;
  }
}

.area {
  width: 64px;
  height: 24px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
}

/deep/ .ant-tree-node-selected {
  background: #f0f3f7 !important;
}
</style>
