import { loginLayouts, adminLayouts } from "@/layouts";

export const masterRouterMap = [
  {
    component: loginLayouts,
    path: "*",
    redirect: "/404",
    children: [
      {
        path: "/404",
        component: () => import("@/views/element/404"),
      },
    ],
  },
  {
    path: "/",
    name: "log",
    redirect: "/login",
    component: loginLayouts,
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("@/views/login/login"),
      },
    ],
  },
  {
    path: "/index",
    redirect: "/peopleApply"
  },
  {
    path: "/data",
    name: "/data",
    redirect: "/element",
    component: adminLayouts,
    meta: {
      pageTitle: "系统管理",
    },
    children: [
      {
        path: "/element",
        name: "element",
        component: () => import("@/views/element/element"),
        meta: {
          pageTitle: "测试",
          noWhiteBg: true,
        },
      },
      {
        path: "/roleAdmin",
        name: "roleAdmin",
        component: () => import("@/views/systemRun/roleAdmin"),
        meta: {
          pageTitle: "角色管理",
        },
      },
      {
        path: "/roleAdminEdit/:id",
        name: "roleAdminEdit",
        component: () => import("@/views/systemRun/roleAdminEdit"),
        meta: {
          pageTitle: "添加角色",
        },
      },
      {
        path: "/accountAdmin",
        name: "accountAdmin",
        component: () => import("@/views/systemRun/accountAdmin"),
        meta: {
          pageTitle: "账号管理",
        },
      },
    ],
  },
];
