<template>
  <div class="login_layouts">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "loginLayouts",
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.login_layouts{
    height: 100%;
}
</style>
