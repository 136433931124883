<template>
  <div class="role_admin_edit">
    <a-card
      :title="delRoleItemId > -1 ? '编辑管理员' : '添加管理员'"
      :bordered="false"
    >
      <a-spin tip="加载中..." :spinning="formLoading">
        <a-form
          :form="formModal"
          @submit="handleSubmit"
          :labelCol="labelCol"
          :wrapperCol="wrapCol"
        >
          <a-form-item label="角色名称">
            <a-input
              allowClear
              v-decorator="[
                'role_name',
                { rules: [{ required: true, message: '请输入角色名称' }] },
              ]"
              placeholder="请输入角色名称"
            ></a-input>
          </a-form-item>
          <a-form-item label="状态">
            <a-radio-group
              v-decorator="[
                'role_status',
                { initialValue: 1 },
                { rules: [{ required: true, message: '请选择状态' }] },
              ]"
            >
              <a-radio :value="1">正常</a-radio>
              <a-radio :value="2">禁用</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item
            label="权限设置"
            required
            :validateStatus="validateStatus"
            :help="helpTxt"
            :labelCol="{ span: 24 }"
            :wrapperCol="{ span: 24 }"
          >
            <div class="tree_area" v-if="treeData.length > 0">
              <a-tree
                :treeData="treeData"
                checkable
                :replaceFields="replaceFields"
                defaultExpandAll
                v-model="roleMenuidList"
              ></a-tree>
            </div>
          </a-form-item>

          <div class="btns_area_modal center">
            <a-button type="primary" html-type="submit">确定</a-button>
          </div>
        </a-form>
      </a-spin>
    </a-card>
  </div>
</template>

<script>
import * as ajax from "@/api/systemRun";
import { mapMutations } from "vuex";
export default {
  name: "roleAdminEdit",
  data() {
    return {
      delRoleItemId: -1,
      labelCol: {
        span: 9,
      },
      wrapCol: {
        span: 6,
      },
      treeData: [],
      replaceFields: {
        children: "children",
        title: "menu_name",
        key: "menu_id",
      },
      formModal: this.$form.createForm(this, { name: "roleFormModal" }),
      formLoading: false,
      roleMenuidList: [],
      validateStatus: "success",
      helpTxt: "",
    };
  },
  created() {
    // if (this.$route.params.id > -1) {
    //   this.delRoleItemId = this.$route.params.id;
    //   this.modifyPageT("编辑角色");
    //   this.getRoleDetails();
    // } else {
    //   this.getPageListMenu();
    // }
  },
  methods: {
    ...mapMutations(["modifyPageT"]),
    openNotification: function(type, msg) {
      this.$notification[type]({
        message: "系统提示",
        description: msg,
      });
    },
    getRoleDetails() {
      this.formLoading = true;
      ajax
        .findOneRole(this.delRoleItemId)
        .then((res) => {
          ajax.getMenuAll(this.delRoleItemId).then((result) => {
            this.treeData = result.data.data;
            let roleMenuidList = [];
            result.data.data.map((item) => {
              if (item.is_check == 1) {
                roleMenuidList = [
                  ...roleMenuidList,
                  item.menu_id,
                  ...item.children.map((el) => {
                    return item.menu_id;
                  }),
                ];
              } else {
                item.children.map((el) => {
                  if (el.is_check == 1) {
                    roleMenuidList.push(el.menu_id);
                  }
                });
              }
            });
            this.roleMenuidList = roleMenuidList;
            this.formLoading = false;
          });
          this.formModal.setFieldsValue(res.data.data);
        })
        .catch((res) => {
          this.openNotification("error", res.data.errmsg);
          this.$router.go(-1);
        });
    },
    getPageListMenu() {
      ajax.getMenuAll().then((res) => {
        this.treeData = res.data.data;
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.formModal.validateFields((err, values) => {
        if (!err) {
          if (this.roleMenuidList.length <= 0) {
            this.validateStatus = "error";
            this.helpTxt = "请选择角色权限";
            return;
          } else {
            this.validateStatus = "success";
            this.helpTxt = "";
          }

          this.formLoading = true;
          let api = ajax.addRole;
          if (this.delRoleItemId != -1) {
            values.role_id = this.delRoleItemId;
            api = ajax.updateRole;
          }
          values.menu_list = this.roleMenuidList;
          api(values)
            .then((res) => {
              this.openNotification("success", res.data.errmsg);
              this.$router.go(-1);
            })
            .catch((res) => {
              this.formLoading = false;
              this.openNotification("error", res.data.errmsg);
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.btns_area_modal {
  display: flex;
  padding-top: 20px;
  justify-content: space-between;
  align-items: center;
  &.center {
    padding-top: 0;
    justify-content: center;
    button {
      width: 200px;
    }
  }
  button {
    width: 110px;
  }
}
.tree_area {
  height: 550px;
  padding-left: 400px;
  border: 1px solid #e6e6e6;
  overflow-y: auto;
}
</style>
