<template>
  <div class="update_pass_word">
    <a-modal
      title="重置密码"
      v-model="adminModal"
      centered
      :footer="null"
      destroyOnClose
      :width="editWidth"
    >
      <a-form-model
        :model="form"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        ref="ruleForm"
        :rules="rules"
      >
        <a-form-model-item label="密码" prop="password">
          <a-input
            v-model="form.password"
            placeholder="请输入密码"
            allowClear
            type="password"
            autocomplete="off"
          />
        </a-form-model-item>
        <a-form-model-item label="确认密码" prop="checkPassword" required>
          <a-input
            v-model="form.checkPassword"
            placeholder="请再次确认密码"
            allowClear
            type="password"
            autocomplete="off"
          />
        </a-form-model-item>
      </a-form-model>
      <div class="btns">
        <a-button
          type="primary"
          class="btn_ses"
          :loading="btnLoading"
          @click="submitBtn"
          >确认修改</a-button
        >
      </div>
    </a-modal>
  </div>
</template>

<script>
import { updatePassword } from "@/api/systemRun";
export default {
  name: "updatePassWord",
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("请输入相同的密码"));
      } else {
        callback();
      }
    };
    return {
      btnLoading: false,
      labelCol: { span: 5 },
      wrapperCol: { span: 16 },
      adminModal: false,
      editWidth: 480,
      form: {
        password: "",
        checkPassword: "",
      },
      rules: {
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        checkPassword: [{ validator: validatePass, trigger: "blur" }],
      },
    };
  },
  components: {},
  methods: {
    showModal() {
      this.form = {
        password: "",
        checkPassword: "",
      };
      this.btnLoading = false;
      this.adminModal = true;
    },
    openNotification: function(type, msg) {
      this.$notification[type]({
        message: "系统提示",
        description: msg,
      });
    },
    submitBtn() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.btnLoading = true;

          updatePassword(this.form.password)
            .then((res) => {
              this.btnLoading = false;
              this.openNotification("success", res.data.errmsg);
              this.adminModal = false;
            })
            .catch((res) => {
              this.btnLoading = false;
              this.openNotification("error", res.data.errmsg);
            });
        } else {
          return;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.btns {
  text-align: center;
  .btn_ses {
    width: 180px;
  }
}
</style>
