import { render, staticRenderFns } from "./editorArea.vue?vue&type=template&id=970c066a&scoped=true&"
import script from "./editorArea.vue?vue&type=script&lang=js&"
export * from "./editorArea.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "970c066a",
  null
  
)

export default component.exports