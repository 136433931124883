<!--
 * @Descripttion: 
 * @version: v1.0
 * @Author: Welkin
 * @Date: 2021-05-22 16:20:06
 * @LastEditors: Welkin
 * @LastEditTime: 2021-06-10 13:26:02
-->
<template>
<div >
      <div   class="table-page-search-wrapper">
        <a-form :label-col="labelCol" :wrapper-col="wrapperCol" :style="{marginTop:'10px',marginBottom:'-10px'}">
          <a-row>
            <a-col :span="7">
              <a-form-item label="监控类别">
                <a-select v-model="queryParam.type" placeholder="请选择" @change="handleChange">
                  <a-select-option v-for="d in typeOptions" :key="d.id" :value="d.id">{{ d.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
        <br/>
        <a-list
        :grid="{ gutter: 16, column: 3 }"
        :data-source="roundData"
        :pagination="pagination"
        
        >
          <a-list-item slot="renderItem" slot-scope="item, index">
            <!-- <a-card :title="item.title">
              
            </a-card> -->
            <vue-aliplayer-v2 :source="item" :style="{'min-height': '20vh'}" ref="VueAliplayerV2" :options="options" :cssLink="cssLink" :scriptSrc="scriptLink"/>
          </a-list-item>
        </a-list>
        
      </div>
</div>
</template>

<script>
import VueAliplayerV2 from 'vue-aliplayer-v2';
import {
  getDicDropDown,roundList
  
} from '@/api/video'
export default {
  name: 'VedioPolling',
  components: {
    VueAliplayerV2:VueAliplayerV2.Player
  },
  data() {
    return {
      cssLink:"../aliplayer-min.css",
      scriptLink:'../aliplayer-min.js',
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 },
      },
      queryParam: {
        page: 1,
        pageSize: 9,
        type: 1,
      },
      typeOptions:{},
      roundData: [],
      options: {
        source: '',
        isLive: true
      },
      pagination :{
      
        current: 1,
        total: 0,
        pageSize: 9,//每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ["9"],//每页中显示的数据
        showTotal: total => `共有 ${total} 条数据`,  //分页中显示总的数据
        onChange: this.handleTableChange
      },
      
    }
  },
  computed: {
    
    
  },
  created (){
    this.getDicDropDown({id:12})
  },
 
  methods: {
    //监控类别数据字典
    getDicDropDown: async function (parameter) {
      // let res = await this.$store.dispatch(
      //   "getDicDropDown",
      //   Object.assign(parameter)
      // );
      // if (res.code == 200) {
      //   this.typeOptions = res.result
      // }
      getDicDropDown(Object.assign(parameter)).then(res=>{
       if (res.code == 200) {
        this.typeOptions = res.result
      }
      })
    },
    //获取轮巡列表数据
    loadList: function (parameter) {
    // let res = await this.$store.dispatch(
    //   "roundList",
    //   Object.assign(parameter, this.queryParam)
    // );
    // if (res.code == 200) {
    //   this.roundData = res.result.list
    //   this.pagination.total = res.result.total
    // }
    roundList( Object.assign(parameter, this.queryParam)).then(res=>{
      if (res.code == 200) {
      this.roundData = res.result.list
      this.pagination.total = res.result.total
    }
    })
  },
  //更换监控类别
    handleChange(value) {
      console.log(`selected ${value}`);
      this.queryParam.type = value
      this.pagination.current = 1
      this.queryParam.page = 1
      this.roundData = [] // ###############
      this.loadList(this.queryParam)
    },
    //更换学校
    changeSchool(value){
      console.log('school',value)
      this.queryParam.schoolId = value
      this.pagination.current = 1
      this.queryParam.page = 1
      this.roundData = [] // ##############
      this.loadList(this.queryParam)
    },
    //暂停播放
    stop(){
      this.roundData = []
    },
    //分页
    handleTableChange(page,pageSize) {
      console.log('page:',page)     
      console.log('pageSize:',pageSize) 
      this.pagination.current = page
      this.pagination.pageSize = pageSize
      this.queryParam.page = page
      this.queryParam.pageSize = pageSize
      this.roundData = []
      this.loadList(this.queryParam)
      
    },
  },
}
</script>
<style scoped>
/* @import '~@assets/less/common.less'; */
.left {
  min-height: '85vh'
}
</style>