<template>
  <div class="clearfix">
    <!--       -->
    <a-upload
    :disabled="disabled"
        name="file"
        :multiple="false"
        :fileList="fileListList"
        :action="uploadUrl"
        :headers="headers"
        @change="handleChange"
    >
        <div class="picList">
            <a-icon type="file-add" class="addFile"/>
            <span>点击上传文件</span>
        </div>
    </a-upload>
  </div>
</template>

<script>
/*
  用于文件上传

  引入 import UploadFile from "@/component/uploadFile/uploadFile";
  使用 <UploadFile @setFile="setFile" :fileList="fileList"/>
  setFile 组件返回的值 fileList 显示的文件数组
  data(){
    return{
      fileList:[]
    }
  }
  methods:{
    setFile(val){
      this.fileList=val
    }
  }
*/
import { API_HOST } from "@/server/network1";

export default {
  name: "UpdataImage",
  data() {
    return {
      uploadUrl: API_HOST + "/school/goApply/upload",
      API_HOST,
      fileListList: [],
      imageUrl: [],
    };
  },
  props: {
    fileList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    disabled: Boolean,

  },
  watch: {
    fileList: {
      handler(value, oldValue) {
        let that = this;
        let array = [];
        console.log(value,'1123')
        if (value.indexOf("") == -1) {
          value.forEach((item, index) => {
            let json = {
              uid: index + 1,
              name: item.name || item.response.data.name,
              status: "done",
              url: item.url || item.thumbUrl || item.response.data.url || "",
            };
            array.push(json);
          });
          that.fileListList = array;
          this.imageUrl = value;
        }
      },
    },
  },
  computed: {
    headers() {
      return {
        Authorization: localStorage.getItem("jx_token"),
        zjUnicomToken: localStorage.getItem("jx_token1")
          ? localStorage.getItem("jx_token1")
          : "",
      };
    },
  },
  created() {},
  components: {},
  mounted() {},
  methods: {
    handleChange({ fileList }) {
      this.fileListList = fileList;
      console.log(fileList)
      this.$nextTick(() => {
        let imgPic = fileList.map((item) => {
          return {
            url : item.url ? item.url : item.response ? item.response.data.url : "",
            name : item.name ? item.name : item.response ? item.response.data.name : ""
          }
          // return item.url ? item.url : item.response ? item.response.data.url : ""
        });
        this.imageUrl = imgPic
        this.$emit("setFile", imgPic);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.picList{
    display: flex;
    align-items: center;
    span{
        margin-left: 5px;
        color: #101010;
        cursor: pointer;
    }
    .addFile{
        color: #3985F9;
        font-size: 29px;
        cursor: pointer;
    }
    img{
        margin-right: 8px;
        width: 80px;
        height: 80px;
    }
}
</style>
