<template>
  <div class="deviceBill">
    <div class="pageHeader">
        <span>信息详情</span>
        <div class="pageHeaderBtns">
            <a-button @click="backTo">返回</a-button>
        </div>
    </div>
    <div class="pageContent">
        <div class="pageContentTitle">{{pageData.msgTitle}}</div>
        <div class="pageContentHead">
            <span>{{pageData.msgDept}}</span>
            <span class="newsPerson">{{pageData.msgPublisherName}}</span>
            <span class="newsTime" v-if="pageData.createTimeStr">发布时间：{{pageData.createTimeStr}}</span>
        </div>
        <div class="pageContentInfo" v-html="pageData.msgContent">
            <!-- {{pageData.msgContent}} -->
        </div>
        <div class="pageContentFile" v-if="defaultFileList.length > 0">
            <span>附件：</span>
            <a-upload
                :default-file-list="defaultFileList"
                :remove="false"
                :showUploadList="fileOption"
                @preview="downloadFile"
            >
            </a-upload>
        </div>
    </div>
  </div>
</template>

<script>
import {
    getMsgInfoById
} from '@/api/newsIncorruptible'
export default {
  name: "newsInfo",
  data() {
    return {
        defaultFileList: [],
        fileOption : {
            showPreviewIcon : true,
            showRemoveIcon : false
        },
        pageData : {
            msgTitle : '',
            msgDept : '',
            msgPublisherName : '',
            createTimeStr : '',
            msgContent : ''
        }
    };
  },
  components: {
    
  },
  created() {
    this.getInfo()
  },
  methods: {
      downloadFile(file){
          console.log(file)
          window.open(file.url)
      },
    backTo(){
        this.$router.go(-1)
    },
    getInfo(){
        let data = {
            id : this.$route.query.id
        }
        getMsgInfoById(data).then(res=>{
            this.pageData = res.data.data
            this.defaultFileList = res.data.data.fileNames?res.data.data.fileNames.map((item,index)=>{
                return {
                    uid : index+1,
                    name : item,
                    status : 'done',
                    url : res.data.data.fileUrls[index]
                }
            }):[]
        })
    }
  },
};
</script>

<style lang="less" scoped>
.searchIpt{
    width: 200px;
}
.deviceBill {
  width: 100%;
  height: 100%;
}
.pageHeader {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  color: rgba(16, 16, 16, 100);
  background: white;
  text-indent: 27px;
  justify-content: space-between;
  span {
    font-weight: bold;
    margin-right: 10px;
  }
  .pageHeaderBtns{
      margin-right: 27px;
      .leftBtn{
          margin-right: 10px;
      }
  }
}
.pageContent{
    margin-top: 20px;
    background: white;
    padding: 27px;
    .pageContentTitle{
        color: rgba(16, 16, 16, 100);
        font-size: 24px;
        font-family: SourceHanSansSC-bold;
        margin-bottom : 20px
    }
    .pageContentHead{
        padding-bottom: 14px;
        border-bottom: 1px solid #bbbbbb;
        display: flex;
        align-items: center;
        .newsPerson{
            margin-left: 10px;
        }
        .newsTime{
            margin-left: 10px;
        }
    }
    .pageContentInfo{
        margin-top: 20px;
    }
    .pageContentFile{
        margin-top: 20px;
    }
}
</style>
