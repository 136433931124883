<template>
  <div class="admin_sider">
    <div class="scroll_div">
      <a-menu
        @openChange="onOpenChange"
        theme="dark"
        class="amenu"
        :selectedKeys="defaultSelectedKeys"
        :openKeys="openKeys"
        mode="inline"
      >
        <template v-for="item in silderMenu">
          <a-menu-item
            :key="item.t.menuId"
            v-if="item.childList.length <= 0"
            @click="
              goto(item.t.menuUrl),
                getPushTips(item.t.menuName, item.menuUrl, item.t.menuId)
            "
          >
            <!-- <i :class="['iconfont', item.icon]"></i> -->
            <!-- <icon-font :type="item.icon" theme="twoTone" /> -->

            <span>{{ item.t.menuName }}</span>
          </a-menu-item>
          <a-sub-menu :key="item.t.menuId" v-else>
            <span slot="title">
              <!-- <i :class="['iconfont', item.icon]"></i> -->
              <!-- <icon-font :type="item.icon" /> -->
              <span>{{ item.t.menuName }}</span>
            </span>
            <a-menu-item
              v-for="itemItem in item.childList"
              :key="itemItem.t.menuId"
              @click="
                goto(itemItem.t.menuUrl),
                  getPushTips(
                    itemItem.t.menuName,
                    itemItem.t.menuUrl,
                    itemItem.t.menuId
                  )
              "
              >{{ itemItem.t.menuName }}</a-menu-item
            >
          </a-sub-menu>
        </template>
      </a-menu>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { Icon } from "ant-design-vue";
const IconFont = Icon.createFromIconfontCN({
  scriptUrl: "./../../assets/iconfont/iconfont.js",
});
import {
  getMenu
} from "@/api/public";
export default {
  name: "adminSider",
  data() {
    return {
      collapsed: true,
      rootSubmenuKeys: [],
      openKeys: [],
      silderMenu: [],
    };
  },
  components: {
    IconFont,
  },
  watch: {
    $route(val) {
      this.judgeId(val.path);
      this.openKeys = [this.openKeyId];
    },
    openKeyId() {
      this.openKeys = [this.openKeyId];
    },
  },
  computed: {
    ...mapState(["nowSilderId", "openKeyId"]),
    defaultSelectedKeys() {
      return [this.nowSilderId];
    },
  },
  created() {
    this.openKeys = [this.openKeyId];
    this.getLeftMenu();
  },
  methods: {
    ...mapMutations(["changeSilderClick", "changeOpenKeysId"]),
    getLeftMenu() {
      getMenu()
        .then((res) => {
          this.silderMenu = res.data.data;
        })
        .catch((res) => {});
    },
    openNotification: function(type, msg) {
      this.$notification[type]({
        message: "系统提示",
        description: msg,
      });
    },
    goto(data) {
      this.$router.push(data);
    },
    onOpenChange(openKeys) {
      let latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) == -1
      );
      this.openKeys = latestOpenKey ? [latestOpenKey] : [];
    },
    getPushTips(name, path, id) {},
    judgeId(path) {
      let silderMenu = this.silderMenu;
      let id = "",
        openKeyId = "";
      silderMenu.forEach((item, index) => {
        if (item.childList.length == 0) {
          if (item.t.menuUrl == path) {
            id = item.t.menuId;
            openKeyId = "";
          }
        } else {
          item.childList.forEach((itemChild) => {
            if (itemChild.t.menuUrl == path) {
              openKeyId = item.t.menuId;
              id = itemChild.t.menuId;
            }
          });
        }
      });
      this.$store.commit("changeSilderClick", id == "" ? this.nowSilderId : id);
      this.$store.commit(
        "changeOpenKeysId",
        openKeyId == "" ? this.openKeyId : openKeyId
      );
    },
  },
};
</script>

<style lang="less" scoped>
.admin_sider {
  display: flex;
  // height: 100%;
  flex-direction: column;
  background-color: #001529;
}

.amenu {
  width: 256px;
  // width: 80px;
}

.scroll_div {
  flex-grow: 2;
  overflow-y: auto;
}
</style>
