<template>
<div>
<a-card :title="'学生进出入考勤-'+userName" :headStyle="{fontWeight:'bold'}">
  <a-button type="primary" slot="extra" @click="goback">返回</a-button>
  <div style="font-size:14px;border-left: 3px solid #4292F7;padding-left:5px">数据总览</div>
  <a-row>
    <a-radio-group 
    default-value="week"
    v-model="queryParamPer.type"
    @change="timeChange"
    :style="{ marginBottom: '8px' ,float:'right'}">
      <a-radio-button :value="1">
        周
      </a-radio-button>
      <a-radio-button :value="2">
        月
      </a-radio-button>
    </a-radio-group> 
  </a-row>
    <div  id="stchart" ref="stchart" ></div>
</a-card>
  <br />
  <br/>
  <a-card >
    <div style="font-size:14px;border-left: 3px solid #4292F7;padding-left:5px">每日数据详情</div>
    <a-form 
      style="margin:20px"
      @keyup.enter.native="searchQuery" 
      :model="queryParam"
      layout="inline" 
    >
    
      <a-form-item label="人员姓名">
        <a-input v-model="queryParam.name" />
      </a-form-item>
      <a-form-item label="考勤日期">
        <a-date-picker  :defaultValue="moment(queryParam.date)" @change="onChange" />
        <a-button type="primary" @click="handleSearch" style="margin: 0px 32px"
        >查询</a-button
      >  
      </a-form-item>
   
      
    </a-form>
    <a-table 
      style="margin:20px"
      :row-key="(record,index) =>{return index}"
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <a slot="name" slot-scope="text">{{ text }}</a>
    </a-table>
  </a-card>

  </div>
</template>

<script>
// import { JeecgListMixin } from '@/mixins/JeecgListMixin';
import * as echarts from 'echarts';
import {
 getPercentageOfAttendance,getStudentAttendance
} from '@/api/attendance'
import moment from 'moment'

export default {
  name: 'StudentDetail',
  // mixins: [JeecgListMixin],
  components: {
    
  },
  data() {
    return {
      //数据详情
      queryParam:{
        pageNum: 1,
        pageSize: 10,
        schoolName:JSON.parse(localStorage.getItem('userName'))
      },
      // 出勤率统计
      queryParamPer: {
        date: undefined,
        type: 1,
        countryName:'安吉县',

      },
      pagination: {
        total: 0,
        current:1,
        pageSize: 10,//每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"],//每页中显示的数据
        showTotal: total => `共有 ${total} 条数据`,  //分页中显示总的数据
      },
      
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
      dataDetail:'day',
      data : [],
      columns:[
        {
          title: '学生姓名',
          dataIndex: 'studentName',
          key: 'studentName',
          width: '25%'
        },
        {
          title: '学号',
          dataIndex: 'studentNumber',
          key: 'studentNumber',
          width: '25%'
          
        },
        {
          title: '到校时间',
          dataIndex: 'arrivalTime',
          key: 'arrivalTime',
          sorter: (a, b) => a.arrivalTime - b.arrivalTime,
          // sortDirections: ['descend', 'ascend'],
          defaultSortOrder: 'descend',
          onFilter: (value, record) => record.arrivalTime.indexOf(value) === 0,
          width: '25%'

          // sorter: (a,b)=>{
          //     let aTimeString = a.arrivalTime
          //     let bTimeString = b.arrivalTime
          //     aTimeString = aTimeString.replace(/-/g,'/')
          //     bTimeString = bTimeString.replace(/-/g,'/')
          //     let aTime = new Date(aTimeString).getTime()
          //     let bTime = new Date(bTimeString).getTime()
          //     return aTime - bTime
          // }
        },
        {
          title: '离校时间',
          dataIndex: 'departureTime',
          key: 'departureTime',
          sorter: (a, b) => a.departureTime - b.departureTime,
          defaultSortOrder: 'descend',
          // sortOrder: ['descend', 'ascend'],
          onFilter: (value, record) => record.departureTime.indexOf(value) === 0,
          width: '25%'
          // defaultSortOrder: '(a,b)',
          // sorter: (a,b)=>{
          //     let aTimeString = a.departureTime
          //     let bTimeString = b.departureTime
          //     aTimeString = aTimeString.replace(/-/g,'/')
          //     bTimeString = bTimeString.replace(/-/g,'/')
          //     let aTime = new Date(aTimeString).getTime()
          //     let bTime = new Date(bTimeString).getTime()
          //     return aTime - bTime
          // }
        },
      ],
      option : {
        title: {
        text: '出勤率趋势图',
        },
        tooltip: {
        trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            name: '日期',
            boundaryGap: false,
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
            type: 'value',
            name: '出勤率',
            axisLabel: {
            formatter: '{value} %'
            },
            max: 100,
        },
        series: [{
            data: [],
            areaStyle: {},
            type: 'line',
            itemStyle: {
                color: '#53A8E2'
            },
            areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(83, 168, 226, 0.1)'
                }])
            },
            smooth: true,
            markPoint: {
                data: [
                     {type: 'max', name: '最大值'},
                    // {type: 'min', name: '最小值'}
                ]
            },
        }]  
      }
    }
  },
  mounted() {
    this.Echarts();
    
  },
  created(){
 //   if(this.$route.query.record.schoolName){
      // this.title = this.title + this.$route.query.record.schoolName

      var aData =  new Date()
      var month = aData.getMonth() < 9 ? "0" + (aData.getMonth() + 1) : aData.getMonth() + 1;
      var date = aData.getDate() <= 9 ? "0" + aData.getDate() : aData.getDate();
      // 日期为周
      this.queryParam.date = aData.getFullYear() + "-" + month + "-" + date;// 获取当日时间
      
     

  //  }
          this.getPercentageOfAttendance({countryName:'安吉县'})

     this.getStudentAttendance(
        {
        countryName: '安吉县',
        flag: 1,
        sort: 2
      })
  },
  computed: {
    userName() {
      return localStorage.getItem("userName")
        ? JSON.parse(localStorage.getItem("userName"))
        : "";
    },
  },
  methods: {
    moment,
    //点击查找
    handleSearch() {
      this.queryParam.pageNum = 1;
      this.pagination.current = 1;
      this.getStudentAttendance({
        countryName: '安吉县',
     });
    },

    //出勤率统计
    getPercentageOfAttendance:  function (parameter) {
      // let res = await this.$store.dispatch(
      //   "getPercentageOfAttendance",
      //   Object.assign(parameter, this.queryParamPer)
      // );
      getPercentageOfAttendance(Object.assign(parameter, this.queryParamPer)).then(res=>{
 if (res.code == 200) {
        const List = []
        const ListData = [] 
        res.result.map((item)=>{
          // console.log('item:',item)
          List.push(item.time)
          ListData.push(item.percentage)
        })
        this.option.xAxis.data = List
        this.option.series[0].data = ListData
        this.myChart.setOption(this.option,true);
      }
      })
     
    },
    // 数据折线图切换周月
    timeChange(value){
      console.log('value:',value)
      this.queryParamPer.type = value.target.value
      var aData  = new Date();
      var month = aData.getMonth() < 9 ? "0" + (aData.getMonth() + 1) : aData.getMonth() + 1;
      var date = aData.getDate() <= 9 ? "0" + aData.getDate() : aData.getDate();
      // 日期为周
      if(value.target.value == 1){
        this.queryParamPer.date = aData.getFullYear() + "-" + month + "-" + date
        this.getPercentageOfAttendance({countryName:'安吉县'})
      }else if(value.target.value == 2) // 日期为月
      {
        this.queryParamPer.date = aData.getFullYear() + "-" + month
        this.getPercentageOfAttendance({countryName:'安吉县'})
      }else {
        console.log('报错')
      }
    },
    // 每日数据详情
     getStudentAttendance: function (parameter) {
      // let res = await this.$store.dispatch(
      //   "getStudentAttendance",
      //   Object.assign(parameter, this.queryParam)
      // );
      getStudentAttendance(Object.assign(parameter, this.queryParam)).then(res=>{
        if (res.code == 200) {
        this.data = res.result.data
        this.pagination.total = res.result.total
      }
      })
      
    },
    Echarts() {
      this.myChart = echarts.init(this.$refs.stchart);
      // const options = await DataMap();
      console.log('option',this.option);
      // let option = options.result;
      // 使用刚指定的配置项和数据显示图表。
      this.myChart.setOption(this.option,true);
    },
    //考勤日期选择
    onChange(date, dateString) {
      console.log(date, dateString);
      this.queryParam.date = dateString
      this.queryParam.pageNum = 1
      this.pagination.current = 1
      // this.getStudentAttendance(
      // {
      //   countryName: '安吉县',
      //   schoolName: this.$route.query.record.schoolName,
      // })
    },
    //分页/排序
    handleTableChange(pagination,filters, sorter) {
      console.log('params2',  sorter)
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.queryParam.pageNum = pagination.current;
      this.queryParam.pageSize = pagination.pageSize;
      this.getStudentAttendance(
      {
        countryName: '安吉县',
        flag: sorter.field == 'arrivalTime'?1:(sorter.field == 'departureTime'?2:''),
        sort: sorter.order=='ascend'?1:(sorter.order=='descend'?2:'')
      })
    },
    goback(){
      this.$router.go(-1);//返回上一层
    }
  },
}
</script>
<style scoped>
/* @import '~@assets/less/common.less'; */
#stchart{
  margin:0 auto;
  width: 1477px;
  height: 404px;
  margin-bottom: 60px;
}
</style>