<template>
  <div class="admin_header">
    <div class="title_txt"></div>
    <div class="user_name">
      <a-dropdown>
        <div class="store_name">
          您好，{{ userName }}
          <a-icon type="down" />
        </div>
        <a-menu slot="overlay">
          <!-- <a-menu-item @click="changePassWord">修改密码</a-menu-item> -->
          <a-menu-item @click="signOut">退出登录</a-menu-item>
        </a-menu>
      </a-dropdown>
      <UpdatePassWord ref="updatePassWord" />
    </div>
  </div>
</template>

<script>
import UpdatePassWord from "./updatePassWord";
import {
  getCurrentInfo
} from '@/api/deviceManage'
export default {
  name: "adminHeader",
  data() {
    return {
      userName : ''
    };
  },
  components: {
    UpdatePassWord,
  },
  // computed: {
  //   userName() {
  //     return localStorage.getItem("userName")
  //       ? JSON.parse(localStorage.getItem("userName"))
  //       : "";
  //   },
  // },
  created() {
    this.getUser()
  },
  mounted () {
    if(localStorage.getItem('userName')){
      this.userName = JSON.parse(localStorage.getItem('userName'))
    }
  },
  methods: {
    changePassWord() {
      this.$refs.updatePassWord.showModal();
    },
    signOut() {
      localStorage.clear();
      // this.$router.push("/");
      window.location.href = 'https://campus.saas.ictsoft.cn/smartcampus/admin/#/home'
    },
    
    getUser(){
      let data = {}
      getCurrentInfo(data).then(res=>{
        localStorage.setItem('userName',JSON.stringify(res.data.data.schoolName))
        this.userName = res.data.data.schoolName
      })
    },
  },
};
</script>

<style lang="less" scoped>
.admin_header {
  flex-shrink: 0;
  display: flex;
  height: 64px;
  padding: 0 50px 0 15px;
  background: #fff;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 3px 4px 0px rgba(179, 179, 179, 0.18);
  color: #242836;
  font-weight: 700;
  z-index: 999;
  .title_txt {
    font-size: 16px;
  }
  .store_name {
    cursor: pointer;
  }
}
</style>
