import { fetchEndpoint } from "@/server/network1";
import axios from "axios";
/**
 * fetchEndpoint
 *  @url    api 接口路径
 *  @data   接口传值
 *  @type   true json传参 /false form传参  ,默认json 可不填
 *  @fun    'GET'/'POST'  默认POST 可不填
 */

//后台登录
export const login = (data) => fetchEndpoint("/school/pc/login", data, true, 'POST');

//菜单
export const getMenu = () =>
  fetchEndpoint("/school/user/getCurrentMenus", {});

// //获取七牛token
export const getUploadToken = () =>
  fetchEndpoint("/admin/UploadManager/getUploadToken", {});

//xxxxxxxxxxxxxxxxxx
// export const xxxxxxxxxxxxxxxxxx = (data) => fetchEndpoint(
//     "xxxxxxxxxxxxxxxxxx", data
// )
