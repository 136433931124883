import { adminLayouts } from '@/layouts'

export const planRouterMap = [
  {
    path: '/planMan',
    name: '/planMan',
    redirect: '/planList',
    component: adminLayouts,
    meta: {
      pageTitle: '疫情防控预案管理'
    },
    children: [
      {
        path: '/planList',
        name: 'planList',
        component: () => import('@/views/planMan/planList'),
        meta: {
          pageTitle: '预案列表',
          noWhiteBg: true
        }
      },
      {
        path: '/addPlan',
        name: 'addPlan',
        component: () => import('@/views/planMan/addPlan'),
        meta: {
          pageTitle: '新增预案',
          noWhiteBg: true
        }
      },
      {
        path: '/planInfo',
        name: 'planInfo',
        component: () => import('@/views/planMan/planInfo'),
        meta: {
          pageTitle: '预案详情',
          noWhiteBg: true
        }
      }
    ]
  }
]
