<template>
  <div style="background: white; padding: 20px">
    <a-card title="人防：" style="width: 100%" :bordered="false">
      <PeopleProofing
        @showModal="showModal"
        :type="0"
        @getInfo="getInfo"
        :infoList="personList"
      />
      <div class="btn_area">
        <a-button type="primary" @click="showModal(-1, 0)"> +新建 </a-button>
      </div>
    </a-card>
    <a-card title="物防：" style="width: 100%" :bordered="false">
      <PeopleProofing
        @showModal="showModal"
        :type="1"
        @getInfo="getInfo"
        :infoList="objectList"
      />
      <div class="btn_area">
        <a-button type="primary" @click="showModal(-1, 1)"> +新建 </a-button>
      </div>
    </a-card>
    <a-card title="技防：" style="width: 100%" :bordered="false">
      <PeopleProofing
        @showModal="showModal"
        :type="2"
        @getInfo="getInfo"
        :infoList="skillList"
      />
      <div class="btn_area">
        <a-button type="primary" @click="showModal(-1, 2)"> +新建 </a-button>
      </div>
    </a-card>
    <a-modal
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-spin :spinning="spinning" tip="加载中……">
        <a-form-model
          :model="detailForm"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :rules="rules"
          ref="ruleForm"
        >
          <a-form-model-item label="名称" prop="tdeMaintenance">
            <a-select
              v-model="detailForm.tdeMaintenance"
              placeholder="请选择"
              allowClear
            >
              <a-select-option
                :value="item.name"
                v-for="(item, index) in itemList"
                :key="index"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="数量" prop="number">
            <a-input
              v-model="detailForm.number"
              placeholder="请输入"
              allowClear
            />
          </a-form-model-item>
        </a-form-model>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import PeopleProofing from "./component/peopleProofing";
import * as api from "@/api/threeProofing.js";
export default {
  name: "threeProofingList",
  props: {},
  components: { PeopleProofing },
  data() {
    return {
      title: "",
      visible: false,
      spinning: false,
      confirmLoading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      personList: [],
      objectList: [],
      skillList: [],
      schoolId: "",
      detailForm: {
        tdeMaintenance: undefined,
        type: null,
        number: "",
        id: "",
      },
      itemList: [],
      rules: {
        number: [{ required: true, message: "请输入数量", trigger: "blur" }],
        tdeMaintenance: [
          { required: true, message: "请选择名称", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    //获取列表信息
    getInfo() {
      api
        .getThreeProofingsList()
        .then((res) => {
          let info = res.data.data;
          this.personList = info.personList.map((item, index) => {
            return {
              ...item,
              indexNumber: index + 1,
            };
          });
          this.objectList = info.objectList.map((item, index) => {
            return {
              ...item,
              indexNumber: index + 1,
            };
          });
          this.skillList = info.skillList.map((item, index) => {
            return {
              ...item,
              indexNumber: index + 1,
            };
          });
        })
        .catch((res) => {
          this.openNotification("error", res.data.msg);
        });
    },
    //获取编辑信息
    getDetail(id) {
      this.spinning = true;
      api
        .getThreeProofingsById({ id: id })
        .then((res) => {
          this.spinning = false;
          this.detailForm.tdeMaintenance = res.data.data.tdeMaintenance;
          this.detailForm.type = res.data.data.type;
          this.detailForm.number = res.data.data.number;
          this.detailForm.id = id;
        })
        .catch((res) => {
          this.spinning = false;
          this.openNotification("error", res.data.msg);
        });
    },
    //获取类目下拉
    getItemList(type) {
      api.getTypeList({ type }).then((res) => {
        this.itemList = res.data.data;
      });
    },
    //打开新增编辑
    showModal(id, type) {
      this.visible = true;
      this.getItemList(type);
      if (id === -1) {
        this.title = "新增";
        this.detailForm.tdeMaintenance = undefined;
        this.detailForm.type = type;
        this.detailForm.number = "";
        this.detailForm.id = "";
      } else {
        this.title = "编辑";
        this.getDetail(id);
      }
    },
    //确认新增编辑
    handleOk(e) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let reqInterface;
          let unit = this.itemList.filter((item) => {
            return item.name === this.detailForm.tdeMaintenance;
          })[0].unit;
          let _data = { ...this.detailForm, schoolId: this.schoolId, unit };
          if (this.title === "新增") {
            reqInterface = api.addThreeProofing;
          } else if (this.title === "编辑") {
            reqInterface = api.upaThreeProofings;
          }
          this.confirmLoading = true;
          reqInterface(_data)
            .then((res) => {
              this.confirmLoading = false;
              this.openNotification("success", res.data.msg);
              this.visible = false;
              this.getInfo();
            })
            .catch((res) => {
              this.confirmLoading = false;
              this.openNotification("error", res.data.msg);
            });
        } else {
          return false;
        }
      });
    },
    //弹出框取消
    handleCancel(e) {
      this.visible = false;
    },
    //获取学校信息
    getSchoolInfo() {
      api.getSchoolDetail().then((res) => {
        this.schoolId = res.data.data.schoolId;
      });
    },
    //提示
    openNotification: function (type, msg) {
      this.$notification[type]({
        message: "系统提示",
        description: msg,
      });
    },
  },
  mounted() {
    this.getInfo();
    this.getSchoolInfo();
  },
};
</script>

<style lang="less" scoped>
.btn_area {
  margin-top: 10px;
  display: flex;
  justify-content: right;
}
/deep/.ant-card-head {
  border-bottom: 0px;
}
</style>
