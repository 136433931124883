<template>
  <div class="login">
    <div class="login_area">
      <div class="login_title">用户登录</div>
      <div class="form_area">
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          @submit="handleSubmit"
        >
          <a-form-model-item prop="account">
            <div class="form_item">
              <img class="head_img" src="@/assets/login/login_user.png" alt />
              <a-input
                class="login_input"
                v-model="form.account"
                placeholder="请输入您的用户名"
              />
            </div>
          </a-form-model-item>
          <a-form-model-item prop="password">
            <div class="form_item">
              <img
                class="head_img"
                src="@/assets/login/login_password.png"
                alt
              />
              <a-input
                class="login_input"
                type="password"
                v-model="form.password"
                placeholder="请输入您的密码"
              />
            </div>
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              class="login_btn"
              :loading="loginBtnLoading"
              block
              html-type="submit"
              >登录</a-button
            >
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
  </div>
</template>

<script>
import {
  login
} from "@/api/public";
export default {
  name: "login",
  data() {
    return {
      loginBtnLoading: false,
      form: {
        account: "",
        password: "",
      },
      rules: {
        account: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  created() {
    if (process.env.NODE_ENV === "development") {
      this.form = {
        account: "admin",
        password: "anjiboe-school!@#123",
      };
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loginBtnLoading = true;
          login(this.form)
            .then((res) => {
              console.log(res,'111')
              localStorage.setItem(
                "jx_token",
                res.data.data
              );
              // this.getUser()
              this.$nextTick(()=>{
                this.$router.push("/element");
              })
            })
            .catch((res) => {
              this.loginBtnLoading = false;
              this.openNotification("error", res.data.msg);
            });
        } else {
          return false;
        }
      });
    },

    // 提示框
    openNotification: function (type, msg) {
      this.$notification[type]({
        message: "系统提示",
        description: msg,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  display: flex;
  height: 100%;
  align-items: center;
  background: url("../../assets/login/login_background.png") no-repeat center
    center fixed;
  background-size: cover;
  .login_area {
    width: 400px;
    height: 460px;
    margin-left: 250px;
    background: #fff;
    border-radius: 18px;
    .login_title {
      position: relative;
      margin-top: 70px;
      font-size: 30px;
      color: #323232;
      text-align: center;
      &::after {
        position: absolute;
        content: "";
        width: 80px;
        height: 6px;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 3px;
        background: #5690f1;
      }
    }
    .form_area {
      margin-top: 40px;
      padding: 0 50px;
      .login_btn {
        margin-top: 35px;
        height: 45px;
        font-size: 18px;
        color: #fff;
        background: #3a92ff;
        border-color: #3a92ff;
        border-radius: 24px;
      }
      .form_item {
        display: flex;
        padding-bottom: 7px;
        margin-top: 10px;
        align-items: center;
        border-bottom: 1px solid #e6e6e6;
        .head_img {
          width: 18px;
          height: 20px;
        }
        .login_input {
          margin-top: 3px;
          border: 0;
          &:focus {
            box-shadow: 0 0 0 0;
          }
        }
      }
    }
  }
}
</style>
