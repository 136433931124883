<template>
  <div class="people_apply">
    <div class="pageInfo">
      <div class="pageInfo_item">
        <div class="pageInfo_item_title">本月申请数</div>
        <div class="pageInfo_item_value thisMonth">
          {{ pageInfo.thisMonth }}
        </div>
      </div>
      <div class="pageInfo_item">
        <div class="pageInfo_item_title">今日申请数</div>
        <div class="pageInfo_item_value thisDay">{{ pageInfo.thisDay }}</div>
      </div>
      <div class="pageInfo_item">
        <div class="pageInfo_item_title">审批中</div>
        <div class="pageInfo_item_value inApproval">
          {{ pageInfo.inApproval }}
        </div>
      </div>
      <div class="pageInfo_item">
        <div class="pageInfo_item_title">未通过</div>
        <div class="pageInfo_item_value failed">{{ pageInfo.fail }}</div>
      </div>
    </div>
    <div class="pageContent">
      <div class="pageContent_head">
        <div class="pageContent_title">外出申请</div>
        <a-button @click="openAddModal">新增外出申请</a-button>
      </div>
      <div class="pageContent_search">
        <a-form-model layout="inline" :model="searchForm">
          <a-form-model-item label="申请编号">
            <a-input
              v-model="searchForm.gocode"
              placeholder="请输入编号"
              class="searchIpt"
            />
          </a-form-model-item>
          <a-form-model-item label="外出类型">
            <a-select
              v-model="searchForm.goType"
              class="searchSelect"
              allowClear
              placeholder="请选择"
            >
              <a-select-option :value="1">研学</a-select-option>
              <a-select-option :value="2">竞赛</a-select-option>
              <a-select-option :value="3">综合实践活动</a-select-option>
              <a-select-option :value="4">其他</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="状态">
            <a-select
              v-model="searchForm.goStatus"
              class="searchSelect"
              allowClear
              placeholder="请选择"
            >
              <a-select-option :value="0">已撤销</a-select-option>
              <a-select-option :value="1">已退回</a-select-option>
              <a-select-option :value="2">待审核</a-select-option>
              <a-select-option :value="3">待钉钉审批</a-select-option>
              <a-select-option :value="4">已完成</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" @click="handleSubmit"> 搜索 </a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="pageContent_table">
        <a-table
          :columns="columns"
          :data-source="dataSource"
          :loading="tableLoading"
          size="middle"
          :pagination="pagination"
        >
          <div slot="number" slot-scope="text, data, index">
            <a-button type="link" @click="openInfo(data.id)">{{
              data.goCode
            }}</a-button>
          </div>
          <div slot="goDate" slot-scope="text, data, index">
            {{
              data.goStartDate+'~'+(data.goEndDate==null? '':data.goEndDate)
            }}
          </div>
          <div slot="goReason" slot-scope="text, data, index">
            <a-tooltip placement="topLeft">
              <template slot="title">
                <span>{{data.goReason}}</span>
              </template>
              <span class="reasonClass">{{data.goReason}}</span>
            </a-tooltip>
            
          </div>
          <div slot="enclosure" slot-scope="text, data, index">
            <a-button
              type="link"
              v-if="data.fileUrls !== null"
              @click="download(data.fileUrls,data.fileNames)"
              >下载附件</a-button
            >
          </div>
          <div slot="goStatus" slot-scope="text, data, index">
            {{
              data.goStatus == 0
                ? "已撤销":
                data.goStatus == 1
                ? "已退回"
                : data.goStatus == 2
                ? "待审核(局端)"
                : data.goStatus == 3
                ? "待钉钉审批"
                : "已完成"
            }}
          </div>
          <div slot="actions" slot-scope="text, data, index">
            <!-- <a-button type="link" class="leftBtn" v-if="index%4==0" @click="openReasonAction">退回</a-button> -->
            <!-- <a-button type="link" v-if="index%4==0" @click="toApproval">进入审批</a-button> -->
            <a-button
              type="link"
              v-if="data.goStatus == 4"
              @click="downloadFile(data.id)"
              >下载流转文件</a-button
            >
            <a-button
              type="link"
              v-if="data.goStatus == 1"
              @click="openReason(data.backReason)"
              >退回理由</a-button
            >
            <a-button
              type="link"
              v-if="data.goStatus == 2"
              @click="openCancel(data.id)"
            >撤回</a-button>
            <a-button
              type="link"
              v-if="data.goStatus == 1 || data.goStatus == 0"
              @click="changeInfo(data.id)"
              >修改</a-button
            >
          </div>
        </a-table>
      </div>
    </div>

    <!-- 退回理由 -->
    <a-modal v-model="visible" title="退回理由" :footer="null" centered>
      <p>{{ reason }}</p>
    </a-modal>

    <!-- 撤回确认 -->
    <a-modal
      v-model="cancelVisible"
      title="撤回确认"
      centered
      @ok="handleCBtnOk"
      @cancel="handleCBtnCancel"
      destroyOnClose
      :confirmLoading="confirmCBtnLoading"
    >
      <p>确认撤销此条记录吗？</p>
    </a-modal>

    <!-- 详情 -->
    <Drawer ref="drawerInfo" :ppId="selectId" />

    <!-- 新增外出申请 -->
    <AddOutApply
      ref="addOutApply"
      :ppId="selectId"
      @refreshTable="getPageList"
    />
  </div>
</template>

<script>
import { getPageList, statistics,cancel } from "@/api/peopleOut";
import Drawer from "./components/drawer";
import AddOutApply from "./components/addOutApply";
import axios from "axios";
import { downLoadTemplate } from "@/server/network1.js";
import { API_HOST } from "@/server/network1";
// import { getToken,removeToken } from '@/utils/cookies'
import router from "@/router";

export default {
  name: "peopleApply",
  data() {
    return {
      pageInfo: {
        thisMonth: 0,
        thisDay: 0,
        inApproval: 0,
        fail: 0,
      },
      searchForm: {
        gocode: "",
        goType: undefined,
        goStatus: undefined,
      },
      visible: false,
      cancelVisible: false,
      tableLoading: false,
      pagination: {
        current: 1,
        showTotal: (total, range) => `第${range.join("-")}条/总共${total}条`,
        onChange: (page, pageSize) => this.changePage(page, pageSize),
        onShowSizeChange: (current, size) => this.showSizeChange(current, size),
        total: 0,
        pageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
      },
      columns: [
        {
          title: "编号",
          align: "center",
          scopedSlots: {
            customRender: "number",
          },
        },
        {
          title: "外出单位",
          dataIndex: "goUnit",
          align: "center",
        },
        {
          title: "外出日期",
          align: "center",
          scopedSlots: {
            customRender: "goDate",
          },
        },
        {
          title: "外出类型",
          align: "center",
          dataIndex: "goTypeName",
        },
        {
          title: "外出学生数",
          dataIndex: "goStuNum",
          align: "center",
        },
        {
          title: "外出目的地",
          dataIndex: "goEndArea",
          align: "center",
        },
        {
          title: "外出事由",
          align: "center",
          scopedSlots: {
            customRender: "goReason",
          },
        },
        {
          title: "附件",
          align: "center",
          scopedSlots: {
            customRender: "enclosure",
          },
        },
        {
          title: "状态",
          align: "center",
          scopedSlots: {
            customRender: "goStatus",
          },
        },
        {
          title: "当前已审批",
          dataIndex: "currentSp",
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          scopedSlots: {
            customRender: "actions",
          },
        },
      ],
      dataSource: [],
      reason: "",
      approvalType: "",
      visible2: false,
      selectId: -1,
      API_HOST,
    };
  },
  components: {
    Drawer,
    AddOutApply,
  },
  created() {
    this.statistics();
    this.getPageList();
  },
  methods: {
    handleSubmit() {
      this.pagination.current = 1;
      this.getPageList();
    },
    openReason(text) {
      this.reason = text;
      this.visible = true;
    },
    handleOk1() {
      this.visible2 = false;
    },
    handleCancel1() {
      this.visible2 = false;
    },
    toApproval() {
      this.approvalType = "";
      this.visible2 = true;
    },
    openInfo(id) {
      this.selectId = id;
      this.$nextTick(() => {
        this.$refs.drawerInfo.showDrawer();
      });
    },
    changeInfo(id) {
      this.selectId = id;
      this.$nextTick(() => {
        this.$refs.addOutApply.showModal();
      });
    },
    openAddModal() {
      this.selectId = -1;
      this.$nextTick(() => {
        this.$refs.addOutApply.showModal();
      });
    },
    downloadFile(id) {
      // window.open(API_HOST + "/school/goApply/downLoadFlowFile?id=" + id);
      // this.loading = true;
      downLoadTemplate("/school/goApply/downLoadFlowFile", {id:id}).then((res) => {
        // this.loading = false;
      });
    },
    getPageList() {
      let data = {
        ...this.searchForm,
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize,
      };
      getPageList(data).then((res) => {
        this.dataSource = res.data.data.records;
        this.pagination.total = res.data.data.total;
      });
    },
    changePage(page) {
      this.pagination.current = page;
      this.getPageList();
    },
    showSizeChange(current, size) {
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.getPageList();
    },
    download(fileList, nameList) {
      // console.log(fileList);
      // fileList.map((item) => {
      //   window.open(item);
      // });
      let data = {
        urls : fileList.join(','),
        names : nameList.join(',')
      }
      axios.post(API_HOST+ '/school/file/downLoadByUrls', data, 
        { 
          responseType: 'blob', 
          headers :  {
            selfType: true,
            Authorization: localStorage.getItem("jx_token")
              ? localStorage.getItem("jx_token")
              : "",
            zjUnicomToken: router.history.current.query.zj_unicom_token
              ? router.history.current.query.zj_unicom_token
              : localStorage.getItem("jx_token1")
              ? localStorage.getItem("jx_token1")
              : "",
          }
        }).then((res) => {
          console.log('res', res);
          const blob = res.data;
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onload = (e) => {
            const a = document.createElement('a');
            a.download = `file.zip`;
            // 后端设置的文件名称在res.headers的 "content-disposition": "form-data; name=\"attachment\"; filename=\"20181211191944.zip\"",
            a.href = e.target.result;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          };
      }).catch((err) => {
        console.log(err.message);
      });
    },
    openCancel(id) {
      this.selectId = id;
      this.cancelVisible = true;
    },
    handleCBtnOk() {
      // alert(id);
      this.confirmCBtnLoading = true;
      let data = {
        id: this.selectId
      };
      cancel(data).then((res)=>{
        console.log(res.data);
        if(res.data.code == 200){
          this.openNotification("success", res.data.msg);
          this.getPageList();
          this.confirmCBtnLoading = false;
          this.cancelVisible = false;
        }else{
          this.openNotification("error", res.data.msg);
        }
        
      });
    },
    handleCBtnCancel() {
      // alert(id);
      this.cancelVisible = false;
    },
    openNotification: function(type, msg) {
      this.$notification[type]({
        message: "系统提示",
        description: msg,
      });
    },
    statistics() {
      let data = {};
      statistics(data).then((res) => {
        this.pageInfo = {
          thisMonth: res.data.data.monthCount,
          thisDay: res.data.data.dayCount,
          inApproval: res.data.data.dealingCount,
          fail: res.data.data.backCount,
        };
      });
    },
  },
};
</script>

<style lang="less" scoped>
.people_apply {
  width: 100%;
  height: 100%;
}
.pageHeader {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  color: rgba(16, 16, 16, 100);
  background: white;
  text-indent: 27px;
}
.pageInfo {
  width: 100%;
  margin-bottom: 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pageInfo_item {
  width: calc(25% - 6px);
  height: 109px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background: white;
}
.pageInfo_item_title {
  color: rgba(16, 16, 16, 100);
}
.pageInfo_item_value {
  font-size: 20px;
}
.pageContent {
  width: 100%;
  padding: 15px 20px;
  background: white;
}
.pageContent_title {
  color: rgba(16, 16, 16, 100);
}
.searchSelect {
  width: 167px;
}
.searchIpt {
  width: 200px;
}
.pageContent_table {
  margin-top: 17px;
}
.leftBtn {
  margin-right: 5px;
}
.approvalSelect {
  width: 100%;
}
.thisMonth {
  color: rgba(50, 145, 248, 100);
}
.thisDay {
  color: rgba(24, 202, 38, 100);
}
.inApproval {
  color: rgba(248, 206, 79, 100);
}
.failed {
  color: rgba(236, 18, 18, 100);
}
.pageContent_head {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
}
.reasonClass{
  display: inline-block;
  margin-right: 2px;
  max-width: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
