<template>
  <div class="deviceInfo">
    <a-modal
      v-model="visible"
      title="查看设备"
      :width="851"
      :footer="null"
      centered
    >
      <div class="modal_info">
        <div class="modal_info_title">基本信息</div>
        <div class="modal_info_content">
          <a-form-model
            :model="pageData"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="设备类型" prop="deviceType">
                  <a-select v-model="pageData.deviceType" disabled>
                    <a-select-option :value="1">视频监控</a-select-option>
                    <a-select-option :value="2">访客机</a-select-option>
                    <a-select-option :value="3">硬盘录像机</a-select-option>
                    <a-select-option :value="4">门禁设备</a-select-option>
                    <a-select-option :value="5">摄像头</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="设备名称" prop="deviceName">
                  <a-input v-model="pageData.deviceName" disabled />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="设备位置" prop="deviceAddress">
                  <a-input v-model="pageData.deviceAddress" disabled />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="设备编码" prop="deviceCode">
                  <a-input v-model="pageData.deviceCode" disabled />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="保修日期" prop="repairTime">
                  <a-date-picker
                    v-model="pageData.repairTime"
                    format="YYYY-MM-DD"
                    disabled
                    class="searchIpt"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="供应商">
                  <a-input v-model="pageData.apply" disabled />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="联系人">
                  <a-input v-model="pageData.lxr" disabled />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="联系方式">
                  <a-input v-model="pageData.phone" disabled />
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </div>
      </div>
      <div class="repairRecord">
        <div class="repairRecord_head">
          <div class="repairRecord_title">保修记录</div>
          <a-button type="primary" @click="openAddRepair">新增报修</a-button>
        </div>
        <a-table
          :columns="columns"
          :data-source="dataSource"
          :loading="tableLoading"
          size="middle"
          :pagination="pagination"
        >
          <div slot="repairStatus" slot-scope="text, data, index">
            <span :class="data.repairStatus == 0 ? 'green' : 'orange'">
              {{ data.repairStatus == 0 ? "已修复" : "修复中" }}
            </span>
          </div>
          <div slot="actions" slot-scope="text, data, index">
            <a-button type="link" @click="toInfo(data.id)">详情</a-button>
          </div>
        </a-table>
      </div>
    </a-modal>
    <RepairInfo ref="repairInfo" :reportId="reportId" />
    <AddRepair
      ref="addRepair"
      :deviceCode="pageData.deviceCode"
      @refreshTable="refreshTable"
    />
  </div>
</template>

<script>
import moment from "moment";
import RepairInfo from "./repairInfo";
import AddRepair from "./addRepair";
import { deviceInfo, getRepairListByCode } from "@/api/deviceManage";
export default {
  name: "deviceInfo",
  data() {
    return {
      tableLoading: false,
      pagination: {
        current: 1,
        showTotal: (total, range) => `第${range.join("-")}条/总共${total}条`,
        onChange: (page, pageSize) => this.changePage(page, pageSize),
        onShowSizeChange: (current, size) => this.showSizeChange(current, size),
        total: 0,
        pageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
      },
      visible: false,
      pageData: {
        deviceType: 1,
        deviceName: "",
        deviceAddress: "",
        deviceCode: "",
        repairTime: "",
        apply: "",
        lxr: "",
        phone: "",
      },
      labelCol: { span: 6, offset: 2 },
      wrapperCol: { span: 14 },
      columns: [
        {
          title: "报修内容",
          dataIndex: "reportRemark",
          align: "center",
        },
        {
          title: "报修人",
          dataIndex: "reportUserName",
          align: "center",
        },
        {
          title: "报修时间",
          dataIndex: "createTime",
          align: "center",
        },
        {
          title: "维修状态",
          align: "center",
          scopedSlots: {
            customRender: "repairStatus",
          },
        },
        {
          title: "操作",
          align: "center",
          scopedSlots: {
            customRender: "actions",
          },
        },
      ],
      dataSource: [],
      reportId: -1,
    };
  },
  components: {
    RepairInfo,
    AddRepair,
  },
  props: {
    deviceId: {
      type: Number,
      default: -1,
    },
  },
  methods: {
    moment,
    showModal() {
      this.getInfo();
      this.visible = true;
    },
    refreshTable() {},
    toInfo(id) {
      this.reportId = id;
      this.$nextTick(() => {
        this.$refs.repairInfo.showModal();
      });
    },
    openAddRepair() {
      this.$refs.addRepair.showModal();
    },
    getInfo() {
      let data = {
        id: this.deviceId,
      };
      deviceInfo(data).then((res) => {
        this.pageData = {
          deviceType: res.data.data.type,
          deviceName: res.data.data.name,
          deviceAddress: res.data.data.address,
          deviceCode: res.data.data.code,
          repairTime: res.data.data.repairTimeStr,
          apply: res.data.data.supplier,
          lxr: res.data.data.contacts,
          phone: res.data.data.contactInfo,
        };
        this.getRecords();
      });
    },
    getRecords() {
      let data = {
        code: this.pageData.deviceCode,
        page: this.pagination.current,
        pageSize: this.pagination.pageSize,
      };
      getRepairListByCode(data).then((res) => {
        this.dataSource = res.data.data.records;
        this.pagination.total = res.data.data.total;
      });
    },
    changePage(page) {
      this.pagination.current = page;
      this.getRecords();
    },
    showSizeChange(current, size) {
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.getRecords();
    },
  },
};
</script>

<style lang="less" scoped>
.modal_info_content {
  margin-top: 20px;
}
.modal_info {
  margin: 0 50px;
  border-bottom: 1px solid #bbbbbb;
}
.repairRecord {
  margin: 0 50px;
}
.repairRecord_title {
  margin: 20px 0;
}
.green {
  color: #62b968;
}
.orange {
  color: #f08b49;
}
.searchIpt {
  width: 100%;
}
.repairRecord_head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
