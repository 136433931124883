<!--
 * @Descripttion: 
 * @version: v1.0
 * @Author: Welkin
 * @Date: 2021-05-27 19:39:43
 * @LastEditors: Welkin
 * @LastEditTime: 2021-06-10 10:29:53
-->
<template>
  <div >
    <a-card :title="title" class="left">
    <div   class="table-page-search-wrapper">
      
      <a-form :model="queryParam" @keyup.enter.native="searchQuery" >
        <a-row>
          <a-button type="primary" ghost @click="goback" :style="{float:'right',marginTop:'10px'}">返回</a-button>
        </a-row>
        <a-row :style="{marginLeft:'20px'}">
          <!-- <a-col :span="5">
            <a-form-item >
              <a-date-picker v-model="queryParam.date" placeholder="请选择日期" @change="onChange" />
            </a-form-item>
          </a-col> -->
          <a-col :span="10">
            <a-form-item >
              <a-range-picker
                v-model="queryParam.time" 
                :ranges="{ 今日: [moment(), moment()], '本月': [moment(), moment().endOf('month')] }"
                show-time
                format="YYYY-MM-DD HH:mm:ss"
                @change="TimeOnChange"
              />
            </a-form-item>
          </a-col>
          <a-col :span="3">
            <span span style="float: left;overflow: hidden;margin-top:4px" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <vue-aliplayer-v2  :style="{'min-height': '55vh'}" :source="videoUrl" ref="VueAliplayerV2" :options="options" />
  </a-card>
  </div>
</template>

<script>
import VueAliplayerV2 from 'vue-aliplayer-v2';
import moment from 'moment';
export default {
  name: 'PlayBack',
  components: {
    VueAliplayerV2:VueAliplayerV2.Player
  },
  data() {
    return {
      
      queryParam: {},
      videoUrl: '',
      options: {
        source: '',
        isLive: false
      },
      title: ''
    }
  },
  computed: {
    
  },
   created (){
    console.log('22',this.$route.query.record)
    this.title = sessionStorage.getItem('channelTitle') 
    this.queryParam.channelId = this.$route.query.record.channelId
    const params = {
      channelId: this.queryParam.channelId,
      date: this.queryParam.date,
      endTime: this.queryParam.endTime,
      startTime: this.queryParam.startTime
    }
    this.backUrl(params)

  },
  methods: {
    //实时监控
    backUrl: async function (parameter) {
      let res = await this.$store.dispatch(
        "backUrl",
        Object.assign(parameter)
      );
      if (res.code == 200) {
        this.videoUrl = res.result.url
      }
    }, 

    moment,
    
    // 日期选择
    onChange(date, dateString) {
      console.log(dateString);
      this.queryParam.date = dateString
    },
    //时间范围选择
    TimeOnChange(dates, dateStrings) {
      // console.log('From: ', dates[0], ', to: ', dates[1]);
      console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
      this.queryParam.startTime = dateStrings[0]
      this.queryParam.endTime = dateStrings[1]
    },
    goback(){
      console.log(111111111111)
      this.videoUrl = ''
      this.$router.go(-1);//返回上一层
    },
    searchQuery(){
      const params = {
      channelId: this.queryParam.channelId,
      date: this.queryParam.date,
      endTime: this.queryParam.endTime,
      startTime: this.queryParam.startTime
    }
      this.backUrl(params)
    },
    searchReset(){
      this.queryParam.date = '',
      this.queryParam.time = undefined
      this.queryParam.endTime = '',
      this.queryParam.startTime = '',
      this.searchQuery()
    }
  },
}
</script>
<style scoped>
/* @import '~@assets/less/common.less'; */
.left {
  min-height: 85vh;
}
</style>