import { fetchEndpoint } from "@/server/network1";
import axios from "axios";

//获取信息通知分页数据（本校）
export const getPageList = (data) =>
  fetchEndpoint("/school/upright/information/getPageList", { ...data }, true, "POST");
//获取信息详情
export const getMsgInfoById = (data) =>
  fetchEndpoint("/school/upright/information/getMsgInfoById", { ...data }, true, "POST");
//提交反馈
export const updateBackByMsgCode = (data) =>
  fetchEndpoint(
    "/school/upright/information/updateBackByMsgCode",
    { ...data },
    true,
    "POST"
  );
//查看反馈详情
export const getBackByMsgCode = (data) =>
  fetchEndpoint(
    "/school/upright/information/getBackByMsgCode",
    { ...data },
    true,
    "POST"
  );
