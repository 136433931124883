<!--
 * @Descripttion: 
 * @version: v1.0
 * @Author: Welkin
 * @Date: 2021-05-27 16:47:26
 * @LastEditors: Welkin
 * @LastEditTime: 2021-06-10 13:25:46
-->
<!--
 * @Descripttion: 
 * @version: v1.0
 * @Author: Welkin
 * @Date: 2021-05-27 16:29:37
 * @LastEditors: Welkin
 * @LastEditTime: 2021-05-27 16:44:10
-->
<template>
  <div >
    <a-card :title="title" class="left">
    <a-row>
      <a-button type="primary" ghost @click="goback" :style="{float:'right',marginTop:'10px'}">返回</a-button>
    </a-row>
    <!-- <a-range-picker :style="{marginTop:'20px',marginBottom:'20px'}" show-time @change="PlayTimeChange"/> -->
    <a-row>
      <a-col  :span="7">
        <span :style="{float:'left',marginBottom:'30px',marginLeft:'20px'}">
          通道ID:&nbsp;&nbsp;{{channelId}}
        </span>
        
      </a-col>
      <a-col :span="7">
        <span :style="{float:'left',marginBottom:'30px',marginLeft:'20px'}">
          通道名称:&nbsp;&nbsp;{{channelName}}
        </span>
      </a-col>
      
    </a-row>
    
    <vue-aliplayer-v2  :style="{'min-height': '55vh'}" :source="videoUrl" ref="VueAliplayerV2" :options="options" :cssLink="cssLink" :scriptSrc="scriptLink"/>
  </a-card>
  </div>
</template>

<script>
import VueAliplayerV2 from 'vue-aliplayer-v2';
import {
  realUrl} from '@/api/video'
export default {
  name: 'RealtimePaly',
  components: {
    VueAliplayerV2:VueAliplayerV2.Player
  },
  data() {
    return {
      cssLink:"../aliplayer-min.css",
      scriptLink:'../aliplayer-min.js',
      channelId: '',
      channelName: '',
      videoUrl: '',
      options: {
        source: '',
        isLive: true
      },
      title: ' '
    }
  },
  computed: {
    
  },
   created (){
    console.log('22',this.$route.query.record)
    this.channelId = this.$route.query.record.channelId
    this.channelName = this.$route.query.record.channelName
    this.realUrl({channelId: this.channelId})
    this.title = sessionStorage.getItem('channelTitle') 

  },
  methods: {
    //实时监控
    realUrl: async function (parameter) {
    
      realUrl(Object.assign(parameter, this.queryParam)).then(res=>{
        if (res.code == 200) {
        this.videoUrl = res.result.url
      }
      })
      
    },
    goback(){
      this.videoUrl = ''
      this.$router.go(-1);//返回上一层
    }
  },
}
</script>
<style scoped>
/* @import '~@assets/less/common.less'; */
.left {
  min-height: 85vh;
}
</style>