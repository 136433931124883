<template>
  <div class="deviceInfo">
    <a-modal
      v-model="visible"
      title="报修详情"
      :width="851"
      :footer="null"
      centered
    >
      <div class="modal_info">
        <div class="modal_info_title">
          报修信息<span>（审批编号：{{ pageData.reportNo }}）</span>
        </div>
        <div class="modal_info_content">
          <a-form-model
            :model="pageData"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="所属学校" prop="deviceName">
                  <a-input v-model="pageData.school" disabled />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="设备编码" prop="deviceCode">
                  <a-input v-model="pageData.deviceCode" disabled />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="设备名称" prop="deviceName">
                  <a-input v-model="pageData.deviceName" disabled />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="报修人" prop="repairPerson">
                  <a-input v-model="pageData.repairPerson" disabled />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="联系方式">
                  <a-input v-model="pageData.phone" disabled />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="设备位置" prop="deviceAddress">
                  <a-input v-model="pageData.deviceAddress" disabled />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="报修时间" prop="repairTime">
                  <a-date-picker
                    :default-value="pageData.repairTime"
                    format="YYYY-MM-DD"
                    disabled
                    class="searchIpt"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-model-item
                  label="报修内容"
                  prop="repairTime"
                  :label-col="{ span: 3 }"
                  :wrapper-col="{ span: 19 }"
                >
                  <a-textarea
                    v-model="pageData.deviceAddress"
                    :auto-size="{ minRows: 3, maxRows: 5 }"
                    disabled
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-model-item
                  label="附件照片"
                  prop="repairTime"
                  :label-col="{ span: 3 }"
                  :wrapper-col="{ span: 19 }"
                >
                  <div class="picList" v-viewer>
                    <img
                      :src="item"
                      v-for="(item, index) in pageData.picture"
                      :key="index"
                    />
                  </div>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </div>
      </div>
      <div class="repairRecord">
        <div class="repairRecord_title">维修信息</div>
        <div class="modal_info_content">
          <a-form-model
            :model="pageData"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="维修人" prop="maintainer">
                  <a-input v-model="pageData.maintainer" disabled />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="联系方式" prop="repairPhone">
                  <a-input v-model="pageData.repairPhone" disabled />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="维修状态" prop="repairStatus">
                  <a-input v-model="pageData.repairStatus" disabled />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="维修时间" prop="maintenanceTime">
                  <a-date-picker
                    :default-value="pageData.maintenanceTime"
                    format="YYYY-MM-DD"
                    disabled
                    class="searchIpt"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-model-item
                  label="维修信息"
                  prop="repairMessage"
                  :label-col="{ span: 3 }"
                  :wrapper-col="{ span: 19 }"
                >
                  <a-textarea
                    v-model="pageData.repairMessage"
                    :auto-size="{ minRows: 3, maxRows: 5 }"
                    disabled
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-model-item
                  label="附件照片"
                  prop="repairPic"
                  :label-col="{ span: 3 }"
                  :wrapper-col="{ span: 19 }"
                >
                  <div class="picList" v-viewer>
                    <img
                      :src="item"
                      v-for="(item, index) in pageData.repairPic"
                      :key="index"
                    />
                  </div>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
import { getDeviceReportDetail } from "@/api/deviceManage";
export default {
  name: "deviceInfo",
  data() {
    return {
      visible: false,
      pageData: {
        school: "",
        deviceCode: "",
        deviceName: "",
        repairPerson: "",
        phone: "",
        deviceAddress: "",
        repairTime: "",
        repairContent: "",
        picture: [],
        maintainer: "",
        repairPhone: "",
        repairStatus: "",
        maintenanceTime: "",
        repairMessage: "",
        repairPic: [],
        reportNo: "",
      },
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };
  },
  props: {
    reportId: {
      type: Number,
      default: -1,
    },
  },
  methods: {
    moment,
    showModal() {
      this.getInfo();
      this.visible = true;
    },
    getInfo() {
      let data = {
        reportId: this.reportId,
      };
      getDeviceReportDetail(data).then((res) => {
        this.pageData = {
          school: res.data.data.schoolName,
          deviceCode: res.data.data.deviceCode,
          deviceName: res.data.data.deviceName,
          repairPerson: res.data.data.reportName,
          phone: res.data.data.reportConcat,
          deviceAddress: res.data.data.deviceAddress,
          repairTime: res.data.data.reportTime,
          repairContent: res.data.data.reportRemark,
          picture:
            res.data.data.reportImageList == null
              ? []
              : res.data.data.reportImageList,
          maintainer: res.data.data.repairName,
          repairPhone: res.data.data.repairConcat,
          repairStatus: res.data.data.repairStatus == 1 ? "维修中" : "已修复",
          maintenanceTime: res.data.data.repairTime,
          repairMessage: res.data.data.repairRemark,
          repairPic:
            res.data.data.repairImageList == null
              ? []
              : res.data.data.repairImageList,
          reportNo: res.data.data.reportNo,
        };
      });
    },
  },
};
</script>

<style lang="less" scoped>
.modal_info {
  margin: 0 50px;
  border-bottom: 1px solid #bbbbbb;
}
.repairRecord {
  margin: 0 50px;
}
.repairRecord_title {
  margin: 20px 0;
}
.modal_info_content {
  margin: 20px 20px 0;
}
.picList {
  display: flex;
  align-items: center;
}
.picList img {
  width: 86px;
  height: 80px;
  margin-right: 20px;
}
.modal_info_title span {
  color: #d9d9d9;
}
.searchIpt {
  width: 100%;
}
</style>
