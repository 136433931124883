import { fetchEndpoint } from "@/server/network1";
import axios from "axios";
/**
 * fetchEndpoint
 *  @url    api 接口路径
 *  @data   接口传值
 *  @type   true json传参 /false form传参  ,默认json 可不填
 *  @fun    'GET'/'POST'  默认POST 可不填
 */

//后台登录
// export const login = (data) => fetchEndpoint("/school/pc/login", data);

//菜单
// export const getMenu = () =>
//   fetchEndpoint("/admin/Menu/getMenu", {
//     admin_roleid: JSON.parse(localStorage.getItem("loveUserInfos"))
//       .admin_roleid,
//   });

//获取七牛token
// export const getUploadToken = () =>
//   fetchEndpoint("/admin/UploadManager/getUploadToken", {});

//xxxxxxxxxxxxxxxxxx
// export const xxxxxxxxxxxxxxxxxx = (data) => fetchEndpoint(
//     "xxxxxxxxxxxxxxxxxx", data
// )

//头部
export const getDeviceDetail = (data) =>
  fetchEndpoint("/school/device/getDeviceDetail", { ...data }, true, "POST");
//列表
export const getDeviceList = (data) =>
  fetchEndpoint("/school/device/getDeviceList", { ...data }, true, "POST");
//根据设备code获取报修记录
export const getRepairListByCode = (data) =>
  fetchEndpoint(
    "/school/device/getRepairListByCode",
    { ...data },
    true,
    "POST"
  );
//设备详情
export const deviceInfo = (data) =>
  fetchEndpoint("/school/device/deviceInfo", { ...data }, true, "POST");
//新增设备
export const addDevice = (data) =>
  fetchEndpoint("/school/device/addDevice", { ...data }, true, "POST");
//编辑设备
export const updateDevice = (data) =>
  fetchEndpoint("/school/device/updateDevice", { ...data }, true, "POST");
//删除设备
export const delDevice = (data) =>
  fetchEndpoint("/school/device/delDevice", { ...data }, true, "POST");
//新增报修
export const addDeviceReport = (data) =>
  fetchEndpoint("/school/device/addDeviceReport", { ...data }, true, "POST");
//报修详情
export const getDeviceReportDetail = (data) =>
  fetchEndpoint(
    "/school/device/getDeviceReportDetail",
    { ...data },
    false,
    "POST"
  );
//报修统计
export const deviceReportStatistics = (data) =>
  fetchEndpoint(
    "/school/device/deviceReportStatistics",
    { ...data },
    true,
    "GET"
  );
//报修列表
export const deviceReportList = (data) =>
  fetchEndpoint("/school/device/deviceReportList", { ...data }, true, "POST");
//导入
export const importDevice = (data) =>
  fetchEndpoint("/school/device/importDevice", data);
//获取当前用户信息
export const getCurrentInfo = (data) =>
  fetchEndpoint("/school/user/getCurrentInfo", { ...data }, true, "GET");
//根据设备代码获取设备信息
export const getDeviceByCode = (data) =>
  fetchEndpoint("/school/device/getDeviceByCode", { ...data }, true, "POST");
