<template>
  <div class="deviceBill">
    <div class="pageHeader">
      <span>信息反馈</span>
      <div class="pageHeaderBtns">
        <a-button type="primary" class="leftBtn" @click="submit">反馈</a-button>
        <a-button @click="backTo">返回</a-button>
      </div>
    </div>
    <div class="pageContent">
      <a-form-model ref="ruleForm" :model="formData" :rules="rules">
        <!-- <a-form-model-item
          label="信息标题"
          prop="newsTitle"
          :labelCol="{ span: 6, offset: 0 }"
          :wrapperCol="{ span: 11, offset: 0 }"
          labelAlign="left"
        >
          <a-input v-model="formData.newsTitle" placeholder="请输入" />
        </a-form-model-item> -->
        <a-form-model-item
          required
          label="附件（正文内容用Word（文字+照片）上传，其余辅证内容以压缩包上传）"
          :labelCol="{ span: 12, offset: 0 }"
          :wrapperCol="{ span: 11, offset: 0 }"
          labelAlign="left"
        >
        </a-form-model-item>
        <a-form-model-item
          label=""
          prop="fileList"
          :labelCol="{ span: 6, offset: 0 }"
          :wrapperCol="{ span: 11, offset: 0 }"
          labelAlign="left"
        >
          <UploadFile @setFile="setFile" :fileList="formData.fileList" />
          <span class="uploadRemark"
            >支持扩展名：.rar .zip .doc .docx .pdf .jpg...</span
          >
        </a-form-model-item>
        <!-- <a-form-model-item
          label="信息正文"
          :labelCol="{ span: 6, offset: 0 }"
          :wrapperCol="{ span: 11, offset: 0 }"
          labelAlign="left"
        >
        </a-form-model-item>
        <a-form-model-item
          label=""
          prop="editorData"
          :labelCol="{ span: 6, offset: 0 }"
          :wrapperCol="{ span: 63, offset: 0 }"
          labelAlign="left"
        >
          <Editor
            @setEditContent="setEditContent"
            :editorData="formData.editorData"
          />
        </a-form-model-item> -->
      </a-form-model>
    </div>
  </div>
</template>

<script>
import Editor from "@/component/editorArea/editorArea";
import UploadFile from "@/component/uploadFile/uploadFile1";
import {
  updateBackByMsgCode,
  getBackByMsgCode,
  getMsgInfoById,
} from "@/api/newsManage";
export default {
  name: "newsInfo",
  data() {
    return {
      fileList: [],
      editorData: "",
      formData: {
        fileList: "",
      },
      rules: {
        fileList: [
          { required: true, message: "请选择附件", trigger: "change" },
        ],
      },
    };
  },
  components: {
    Editor,
    UploadFile,
  },
  created() {
    if (this.$route.query.status == 3 || this.$route.query.status == 4) {
      this.getNewsInfo();
    }
  },
  methods: {
    getNewsInfo() {
      let data = {
        receiveCode: this.$route.query.code,
      };
      getBackByMsgCode(data).then((res) => {
        this.formData.newsTitle = res.data.data.receiveTitle;
        this.formData.id = res.data.data.id;
        this.formData.editorData = res.data.data.receiveContent;
        this.formData.fileList = res.data.data.fileUrls
          ? res.data.data.fileUrls.map((item, index) => {
              return {
                uid: index + 1,
                name: res.data.data.fileNames[index],
                status: "done",
                url: item,
              };
            })
          : [];
      });
    },
    backTo() {
      this.$router.go(-1);
    },
    setEditContent(val) {
      this.formData.editorData = val;
    },
    setFile(val) {
      this.formData.fileList = val;
      this.$refs.ruleForm.validateField(["fileList"]);
    },
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let fileUrl = [],
            fileName = [];
          this.formData.fileList.map((item) => {
            fileUrl.push(item.url);
            fileName.push(item.name);
          });
          let data = {
            receiveCode: this.$route.query.code,
            receiveTitle: this.formData.newsTitle,
            receiveContent: this.formData.editorData,
            receiveFile: fileUrl.join(","),
            receiveFileName: fileName.join(","),
          };
          updateBackByMsgCode(data).then((res) => {
            if (res.data.code == 200) {
              this.openNotification("success", res.message);
              this.$router.push("/newsNotice");

              // this.backTo();
            } else {
              this.openNotification("error", res.message);
            }
          });
          // alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    openNotification: function (type, msg) {
      this.$notification[type]({
        message: "系统提示",
        description: msg,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.searchIpt {
  width: 200px;
}
.deviceBill {
  width: 100%;
  height: 100%;
}
.pageHeader {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  color: rgba(16, 16, 16, 100);
  background: white;
  text-indent: 27px;
  justify-content: space-between;
  span {
    font-weight: bold;
    margin-right: 10px;
  }
  .pageHeaderBtns {
    margin-right: 27px;
    .leftBtn {
      margin-right: 10px;
    }
  }
}
.pageContent {
  margin-top: 20px;
  background: white;
  padding: 27px;
}
.uploadRemark {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  font-family: PingFangSC-regular;
}
</style>
