<template>
  <div class="plan-list-wrap">
    <a-card title="">
      <a-form-model style="margin:20px" @keyup.enter.native="searchQuery" layout="inline" :model="searchForm">
        <a-form-model-item label="标题">
          <a-input allowClear placeholder="请输入标题" v-model="searchForm.headline"></a-input>
        </a-form-model-item>
        <a-form-model-item label="预案来源">
          <a-select class="searchSelect" allowClear placeholder="请选择" v-model="searchForm.planSource">
            <a-select-option :value="item" v-for="(item,i) in sourceArr" :key="i">{{item}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="创建人">
          <a-input placeholder="请输入创建人" v-model="searchForm.creator"></a-input>
        </a-form-model-item>
        <a-form-model-item label="创建日期">
          <a-date-picker v-model="searchForm.creationTime" format="YYYY/MM/DD" class="searchIpt" />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="searchQuery">查询</a-button>
          <a-button @click="searchReset" style="margin-left: 8px">重置</a-button>
        </a-form-model-item>
      </a-form-model>
      <div class="title-box">
        <div class="sub-title">预案记录</div>
        <a-button type="primary" @click="addPlan">新建</a-button>
      </div>
      <a-table style="margin:20px" :row-key="(record,index) =>{return index}" :columns="columns" :data-source="dataSource" :pagination="pagination">
        <div slot="action" slot-scope="text, data, index">
          <a-button type="link" @click="watchInfo(data.id)">查看</a-button>
        </div>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import * as api from "@/api/planMan";

export default {
  name: 'PlanList',
  data() {
    return {
      pagination: {
        current: 1,
        showTotal: (total, range) => `第${range.join("-")}条/总共${total}条`,
        onChange: (page, pageSize) => this.changePage(page, pageSize),
        onShowSizeChange: (current, size) => this.showSizeChange(current, size),
        total: 0,
        pageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
      },
      sourceArr:[],
      // dataDetail:'day',
      dataSource: [],
      searchForm: {
        creator: '',//创建人
        creationTime: '',//创建时间
        headline: '',//标题
        planSource: undefined,//预案来源
        issueSchool: undefined,//是否下发学校,0为否,1为是
      },
      columns: [
        {
          title: '序号',
          dataIndex: 'id',
          key: 'id',
          align: 'center',
          width: 80,
          customRender: (text, arr, index) => {
            return (
              (this.pagination.current - 1) * this.pagination.pageSize +
              1 +
              index
            );
          },
        },
        {
          title: '创建时间',
          dataIndex: 'creationTime',
          key: 'creationTime',
          align: 'center'
        },
        {
          title: '标题',
          dataIndex: 'headline',
          key: 'headline',
          align: 'center'
        },
        {
          title: '预案来源',
          dataIndex: 'planSource',
          key: 'planSource',
          align: 'center'
        },
        {
          title: '创建人',
          dataIndex: 'creator',
          key: 'creator',
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 130,
          align: 'center',
          scopedSlots: { customRender: 'action' },
        }
      ]
    }
  },
  computed: {

  },
  mounted() {
  },
  created() {
    this.pagination.current = 1
    this.getSourcePlan()
    this.getList()
  },
  methods: {
    watchInfo(id){
      this.$router.push({
        path:'/planInfo',
        query:{
          id
        }
      })
    },
    openNotification: function (type, msg) {
      this.$notification[type]({
        message: "系统提示",
        description: msg,
      });
    },
    searchQuery() {
      this.pagination.current = 1;
      this.getList()
    },
    searchReset() {
      this.searchForm = {
        creator: '',//创建人
        creationTime: '',//创建时间
        headline: '',//标题
        issueSchool: undefined,//是否下发学校,0为否,1为是
      }
      this.searchQuery()
    },
    addPlan() {
      this.$router.push({
        path: '/addPlan'
      })
    },
    changePage(page) {
      this.pagination.current = page;
      this.getList();
    },
    showSizeChange(current, size) {
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.getList();
    },
    // 预案来源
    getSourcePlan() {
      api.getPlanSource().then((res) => {
        this.sourceArr = res.data.data;
      });
    },
    getList() {
      let creationTime = undefined
      if (this.searchForm.creationTime) {
        creationTime = this.moment(this.searchForm.creationTime).format('YYYY/MM/DD')
      }

      let data = {
        ...this.searchForm,
        creationTime,
        page: this.pagination.current,
        pageSize: this.pagination.pageSize,
      };
      api.getPageList(data).then((res) => {
        this.dataSource = res.data.data.records;
        this.pagination.total = +res.data.data.total;
      });
    },
  },
}
</script>
<style lang="less">
.plan-list-wrap {
  .title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
  }
  .sub-title {
    font-size: 14px;
    border-left: 4px solid #555;
    padding-left: 5px;
    margin-left: 20px;
    color: #000;
  }
  .searchSelect {
    width: 167px;
  }
}
</style>