import {
    adminLayouts
} from "@/layouts";

export const otherRouterMap = [
    {
        path: "/peopleOut",
        name: "/peopleOut",
        redirect: "/peopleApply",
        component: adminLayouts,
        meta: {
            pageTitle: "人员外出",
        },
        children: [
            {
                path: "/peopleApply",
                name: "peopleApply",
                component: () => import("@/views/peopleOut/peopleApply"),
                meta: {
                    pageTitle: "外出申请",
                    noWhiteBg: true,
                },
            },
        ],
    },
    {
        path: "/deviceManage",
        name: "/deviceManage",
        redirect: "/deviceList",
        component: adminLayouts,
        meta: {
            pageTitle: "设备管理",
        },
        children: [
            {
                path: "/deviceList",
                name: "deviceList",
                component: () => import("@/views/deviceManage/deviceList"),
                meta: {
                    pageTitle: "设备台账",
                    noWhiteBg: true,
                },
            },
            {
                path: "/deviceRepair",
                name: "deviceRepair",
                component: () => import("@/views/deviceManage/deviceRepair"),
                meta: {
                    pageTitle: "设备报修",
                    noWhiteBg: true,
                },
            },
        ],
    },
    {
        path: "/visitorManage",
        name: "/visitorManage",
        redirect: "/visitorManage/visitorList",
        component: adminLayouts,
        meta: {
            pageTitle: "访客管理",
        },
        children: [
            {
                path: "/visitorList",
                name: "visitorList",
                component: () => import("@/views/visitorManage/VisitorDetail"),
                meta: {
                    pageTitle: "访客记录",
                    noWhiteBg: true,
                },
            }
        ],
    },
    {
        path: "/attendance",
        name: "/attendance",
        redirect: "/attendance/studentAttendance",
        component: adminLayouts,
        meta: {
            pageTitle: "考勤统计",
        },
        children: [
            {
                path: "/studentAttendance",
                name: "studentAttendance",
                component: () => import("@/views/attendance/StudentDetail"),
                meta: {
                    pageTitle: "学生考勤",
                    noWhiteBg: true,
                },
            }
        ],
    },
    {
        path: "/monitorcenter",
        name: "/monitorcenter",
        redirect: "/monitorcenter/VideoSurveillance",
        component: adminLayouts,
        meta: {
            pageTitle: "监控中心",
        },
        children: [
            {
                path: "/VideoSurveillance",
                name: "VideoSurveillance",
                component: () => import("@/views/monitorcenter/VideoSurveillance"),
                meta: {
                    pageTitle: "视频监控",
                    noWhiteBg: true,
                },
            },
            {
                path: "/VedeoDetail",
                name: "VedeoDetail",
                component: () => import("@/views/monitorcenter/VedeoDetail"),
                meta: {
                    pageTitle: "视频监控",
                    noWhiteBg: true,
                },
            },
            {
                path: "/PlayBack",
                name: "PlayBack",
                component: () => import("@/views/monitorcenter/PlayBack"),
                meta: {
                    pageTitle: "视频监控",
                    noWhiteBg: true,
                },
            },
            {
                path: "/VedeoSurveillanceDetail",
                name: "VedeoSurveillanceDetail",
                component: () => import("@/views/monitorcenter/VedeoSurveillanceDetail"),
                meta: {
                    pageTitle: "视频监控",
                    noWhiteBg: true,
                },
            },
            {
                path: "/RealtimePlay",
                name: "RealtimePlay",
                component: () => import("@/views/monitorcenter/RealtimePlay"),
                meta: {
                    pageTitle: "视频监控",
                    noWhiteBg: true,
                },
            },
            {
                path: "/VideoIframe",
                name: "VideoIframe",
                component: () => import("@/views/monitorcenter/VideoIframe"),
                meta: {
                    pageTitle: "视频监控",
                    noWhiteBg: true,
                },
            },
            {
                path: "/hkVideoIframe",
                name: "hkVideoIframe",
                component: () => import("@/views/monitorcenter/VideoHik"),
                meta: {
                    pageTitle: "海康视频监控",
                    noWhiteBg: true,
                },
            },
        ],
    },
    {
        path: "/news",
        name: "/news",
        redirect: "/newsNotice",
        component: adminLayouts,
        meta: {
            pageTitle: "信息管理",
        },
        children: [
            {
                path: "/newsNotice",
                name: "newsNotice",
                component: () => import("@/views/news/newsNotice"),
                meta: {
                    pageTitle: "信息通知",
                    noWhiteBg: true,
                },
            },
            {
                path: "/newsInfo",
                name: "newsInfo",
                component: () => import("@/views/news/newsInfo"),
                meta: {
                    pageTitle: "信息详情",
                    noWhiteBg: true,
                },
            },
            {
                path: "/newsFeedback",
                name: "newsFeedback",
                component: () => import("@/views/news/newsFeedback"),
                meta: {
                    pageTitle: "信息反馈",
                    noWhiteBg: true,
                },
            },
            {
                path: "/feedbackInfo",
                name: "feedbackInfo",
                component: () => import("@/views/news/feedbackInfo"),
                meta: {
                    pageTitle: "看反馈",
                    noWhiteBg: true,
                },
            },
        ],
    },
    {
        path: "/newsIncorruptible",
        name: "/newsIncorruptible",
        redirect: "/newsIncorruptibleNotice",
        component: adminLayouts,
        meta: {
            pageTitle: "清廉浙江",
        },
        children: [
            {
                path: "/newsIncorruptibleNotice",
                name: "newsIncorruptibleNotice",
                component: () => import("@/views/newsIncorruptible/newsNotice"),
                meta: {
                    pageTitle: "信息通知",
                    noWhiteBg: true,
                },
            },
            {
                path: "/newsIncorruptibleInfo",
                name: "newsIncorruptibleInfo",
                component: () => import("@/views/newsIncorruptible/newsInfo"),
                meta: {
                    pageTitle: "信息详情",
                    noWhiteBg: true,
                },
            },
            {
                path: "/newsIncorruptibleFeedback",
                name: "newsIncorruptibleFeedback",
                component: () => import("@/views/newsIncorruptible/newsFeedback"),
                meta: {
                    pageTitle: "信息反馈",
                    noWhiteBg: true,
                },
            },
            {
                path: "/incorruptibleFeedbackInfo",
                name: "incorruptibleFeedbackInfo",
                component: () => import("@/views/newsIncorruptible/feedbackInfo"),
                meta: {
                    pageTitle: "看反馈",
                    noWhiteBg: true,
                },
            },
        ],
    },
    {
        path: "/schoolBase",
        name: "/schoolBase",
        redirect: "/schoolInfo",
        component: adminLayouts,
        meta: {
            pageTitle: "学校信息",
        },
        children: [
            {
                path: "/schoolInfo",
                name: "schoolInfo",
                component: () => import("@/views/schoolInfo/schoolInfo"),
                meta: {
                    pageTitle: "修改信息",
                    noWhiteBg: true,
                },
            },
        ]
    }
]