<template>
  <div class="account_admin">
    <div class="page_body_area">
      <div class="body_area_top_search">
        <a-form-model layout="inline" :model="form">
          <a-form-item label="账号">
            <a-input
              class="form_item_clas"
              v-model="form.admin_name"
              @keyup.enter="searchList"
              allowClear
              placeholder="请输入"
            ></a-input>
          </a-form-item>
          <a-form-item label="名字">
            <a-input
              class="form_item_clas"
              v-model="form.admin_realname"
              @keyup.enter="searchList"
              allowClear
              placeholder="请输入"
            ></a-input>
          </a-form-item>
          <a-form-item label="状态">
            <a-select
              placeholder="请选择"
              v-model="form.admin_status"
              allowClear
              class="form_item_clas"
            >
              <a-select-option :value="1"> 正常 </a-select-option>
              <a-select-option :value="0"> 禁用 </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-button @click="search" icon="search" type="primary"
              >查询</a-button
            >
          </a-form-item>
          <a-form-item>
            <a-button @click="clearAllSearch">清空</a-button>
          </a-form-item>
          <a-form-item>
            <a-button @click="addNewDetail(-1)" type="primary"
              >新增账号</a-button
            >
          </a-form-item>
        </a-form-model>
      </div>
      <div class="page_table_area">
        <a-table
          rowKey="admin_id"
          :columns="accountAdminColumns"
          :dataSource="dataSource"
          :pagination="pagination"
          :loading="tableLoading"
          size="middle"
        >
          <template slot-scope="data" slot="admin_status">
            <a-switch
              checkedChildren="正常"
              unCheckedChildren="禁用"
              :checked="data.admin_status == 1 ? true : false"
              @change="changeSwithStat($event, data.admin_id)"
            />
          </template>
          <div slot="action" slot-scope="data">
            <a-button type="link" @click="addNewDetail(data.admin_id)"
              >编辑</a-button
            >
            <a-button type="link" @click="delTableItem(data.admin_id)"
              >删除</a-button
            >
          </div>
        </a-table>
      </div>
    </div>
    <EditAccount ref="editAccount" @searchList="searchList" />
  </div>
</template>

<script>
import EditAccount from "./editAccount";
import { accountAdminColumns } from "./columns";
// import * as ajax from "@/api/account";
export default {
  name: "accountAdmin",
  data() {
    return {
      accountAdminColumns,
      form: {
        admin_name: "",
        admin_realname: "",
        admin_status: undefined,
      },
      tableLoading: false,
      dataSource: [],
      pagination: {
        current: 1,
        showTotal: (total, range) => `第${range.join("-")}条/总共${total}条`,
        onChange: (page, pageSize) => this.changePage(page, pageSize),
        onShowSizeChange: (current, size) => this.showSizeChange(current, size),
        total: 0,
        pageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
      },
    };
  },
  created() {
    // this.searchList();
  },
  components: {
    EditAccount,
  },
  methods: {
    clearAllSearch() {
      this.pagination.current = 1;
      (this.form = {
        admin_name: "",
        admin_realname: "",
        admin_status: undefined,
      }),
        this.searchList();
    },
    search() {
      this.pagination.current = 1;
      this.searchList();
    },
    //分页
    changePage(page, pageSize) {
      this.pagination.current = page;
      this.searchList();
    },
    showSizeChange(current, size) {
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.searchList();
    },
    getAccountList() {
      this.pagination.current = 1;
      this.searchList();
    },
    searchList() {
      let data = {
        firstnum: (this.pagination.current - 1) * this.pagination.pageSize,
        num: this.pagination.pageSize,
        ...this.form,
      };
      this.tableLoading = true;
      ajax
        .getAccountList(data)
        .then((res) => {
          let dataSource = res.data.data.list;
          dataSource.map((item, index) => {
            return (item.indexNumber =
              index +
              1 +
              (this.pagination.current - 1) * this.pagination.pageSize);
          });
          this.dataSource = dataSource;
          this.pagination.total = res.data.data.total;
          this.tableLoading = false;
        })
        .catch((res) => {
          this.tableLoading = false;
          this.openNotification("error", res.data.errmsg);
        });
    },
    changeSwithStat(type, id) {
      this.tableLoading = true;
      let data = {
        admin_id: id,
        admin_status: type ? 1 : 0,
      };
      ajax
        .updateAccountStatus(data)
        .then((res) => {
          this.getAccountList();
          this.openNotification("success", res.data.errmsg);
        })
        .catch((res) => {
          this.tableLoading = false;
          this.openNotification("error", res.data.errmsg);
        });
    },
    delTableItem(id) {
      const _this = this;
      this.$confirm({
        title: "确认删除",
        okText: "确认",
        cancelText: "取消",
        onOk() {
          return new Promise((resolve, reject) => {
            ajax
              .delAccount({
                admin_id: id,
              })
              .then((res) => {
                _this.openNotification("success", res.data.errmsg);
                _this.searchList();
                resolve();
              })
              .catch((res) => {
                _this.openNotification("error", "删除失败：" + res.data.errmsg);
                reject();
              });
          }).catch(() => {});
        },
      });
    },
    addNewDetail(id) {
      this.$refs.editAccount.showDrawer(id);
    },
    openNotification: function(type, msg) {
      this.$notification[type]({
        message: "系统提示",
        description: msg,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.form_item_clas {
  width: 250px;
}

.page_table_area {
  padding-top: 20px;
}
</style>
